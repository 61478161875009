<form (ngSubmit)="login(form)" #form="ngForm" autocomplete="off">
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div>
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                    <div class="text-center mb-5">
                        <!-- <img src="assets/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3"> -->
                        <div class="text-900 text-3xl font-medium mb-3">{{ 'messages.signInWelcome' | translate}}</div>

                    </div>
                    <div>
                        <label for="email" class="block text-900 font-medium mb-2">{{'messages.email' |
                            translate}}</label>
                        <input type="text" #email="ngModel" pInputText name="email" [(ngModel)]="model.email"
                            minlength="8" maxlength="80" required [autofocus]="true" class="w-full md:w-30rem mb-3" />

                        <div *ngIf="submitted && !email.valid && (email.dirty || email.touched)">
                            <small class="p-error" *ngIf="email.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="email.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: email.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="email.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: email.errors?.['maxlength'].requiredLength } }}
                            </small>
                        </div>
                        <label for="userPassword" class="block text-900 font-mediummt-3 mb-2">{{'messages.userPassword'
                            |
                            translate}}</label>
                        <p-password #userPassword="ngModel" id="userPassword" name="userPassword" placeholder="{{'messages.userPassword' |
                        translate}}" [toggleMask]="true" [feedback]="false" styleClass="mb-1"
                            inputStyleClass="w-full p-3 md:w-30rem" [(ngModel)]="model.userPassword" minlength="6"
                            maxlength="30"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$"
                            autocomplete="off" [required]="true"></p-password>
                        <div *ngIf="submitted && !userPassword.valid && (userPassword.dirty || userPassword.touched)">
                            <small class="p-error"
                                *ngIf="userPassword.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="userPassword.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: userPassword.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="userPassword.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: userPassword.errors?.['maxlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="userPassword.errors?.['pattern']">
                                {{'validators.pattern.password' | translate }}
                            </small>
                        </div>

                        <div class="flex align-items-center justify-content-between mt-3 gap-5">
                            <div class="flex align-items-center">
                                <!-- <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                                <label for="rememberme1">Remember me</label> -->
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer"
                                style="color: var(--primary-color)"
                                routerLink="../request-password">{{'messages.forgotPassword' |
                                translate}}</a>
                        </div>
                        <button pButton pRipple label="{{'messages.signIn' |
                                translate}}" class="w-full p-3 md:w-30rem mt-3"></button>

                        <div class="flex align-items-center justify-content-between mt-3 gap-5">
                            <a class="text-link" routerLink="../register">{{'messages.registerYourself' |
                                translate}}</a>

                            <a class="font-medium no-underline ml-2 text-right cursor-pointer"
                                style="color: var(--primary-color)"
                                routerLink="../email-confirm">{{'messages.resendMailConfirmation' |
                                translate}}</a>
                        </div>
                    </div>
                    <div class="flex align-items-center justify-content-between mt-3">
                        <div class="col-4">
                            <hr>
                        </div>
                        <div class="text-center">Ou entre usando</div>
                        <div class="col-4">
                            <hr>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between my-3">
                        <div #googleButton>
                            <span class="icon"></span>
                            <span class="buttonText">sssssssssss</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>