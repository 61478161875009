import { Injectable, OnInit } from '@angular/core';
import { UserAuthModel, TokenInfoModel } from '../../models/response/user-auth-model';
import { Observable, ReplaySubject, Subject, Subscription, interval, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginModel } from '../../models/request/identity/login-model';
import { RegisterModel } from '../../models/request/identity/register-model';
import { HttpClientService } from '../infra/http-client.service';
import { StorageService } from '../infra/storage.service';
import { AuthenticationTokenService } from './authentication-token';
import { EmailConfirmationModel } from '../../models/request/identity/email-confirmation-model';
import { ResetPasswordModel } from '../../models/request/identity/reset-password-model';
import { RegisterExternalModel } from '../../models/request/identity/register-external-model';
import { LoginExternalModel } from '../../models/request/identity/login-external-model';
import { OperationResult } from '../../models/response/operation-result';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userTokenKey = 'user-token';
  constructor(
    private httpClient: HttpClientService,
    private storageService: StorageService,
    private authenticationTokenService: AuthenticationTokenService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  private refreshTokenSubscription: Subscription;
  private userSubject = new ReplaySubject<UserAuthModel | null>(1);
  private refreshTokenCheckInterval = 30000;
  user$ = this.userSubject.asObservable();
  //userLogged$ = new Subject<boolean>();

  public getLoginUrl() {
    return `/identity/login`;

  }

  public getRegisterUrl() {
    return `/identity/register`;
  }

  public getUserRefreshTokenUrl() {
    return `/identity/refresh-user-token`;
  }

  public navigateLogin() {

    this.router.navigateByUrl(this.getLoginUrl());
  }

  public navigateRegister() {
    this.router.navigateByUrl(this.getRegisterUrl());
  }

  // authenticate(model: LoginModel): Observable<void> {
  //   return this.httpClient
  //     .executePost<UserAuthModel>(model, this.getLoginUrl())
  //     .pipe(
  //       map((user: UserAuthModel) => {
  //         if (user) {
  //           this.setUser(user);
  //         }
  //       })
  //     );
  // }

  // authenticateExternal(model: LoginExternalModel) {
  //   return this.httpClient.executePost<UserAuthModel>(model, 'identity/loginexternal').pipe(
  //     map((user: UserAuthModel) => {
  //       if (user) {
  //         this.setUser(user);
  //       }
  //     })
  //   )
  // }

  isAuthenticated(): boolean {
    var user = this.getUser();
    if (!user) {
      //console.log("method isAuthenticated user null");
      return false;
    }

    return user ? true : false;
  }

  getUserAccessToken(): string {
    var user = this.getUser();
    return user?.token?.access_token;
  }

  register(model: RegisterModel) {
    return this.httpClient.executePost<RegisterModel>(
      model,
      this.getRegisterUrl()
    );
  }

  registerExternal(model: RegisterExternalModel) {
    return this.httpClient.executePost<RegisterModel>(
      model,
      '/identity/registerexternal'
    );
  }


  login(model: LoginModel): Observable<void> {
    return this.httpClient
      .executePost<UserAuthModel>(model, this.getLoginUrl())
      .pipe(
        map((user: UserAuthModel) => {

          this.setUser(user);
        })
      );
  }

  loginExternal(model: LoginExternalModel) {
    return this.httpClient.executePost<UserAuthModel>(model, 'identity/loginexternal').pipe(
      map((user: UserAuthModel) => {
        if (user) {
          this.setUser(user);
        }
      })
    )
  }

  logout() {
    this.storageService.removeItem(this.userTokenKey);
    this.userSubject.next(null);
  }

  setUser(user: UserAuthModel) {
    if (user) {
      user.tokenInfo = this.authenticationTokenService.createTokenInfo(user.token.access_token);
      this.configureRefresh();
      this.storageService.set(this.userTokenKey, JSON.stringify(user));
      this.userSubject.next(user);
    }
  }

  private configureRefresh() {
    if (!this.refreshTokenSubscription) {
      this.refreshTokenSubscription = interval(this.refreshTokenCheckInterval).subscribe(() => {
        const user = this.getUser();
        if (user) {
          const expired = this.authenticationTokenService.isExpired(user.tokenInfo);
          const willExpire = this.authenticationTokenService.willExpireOnNextInterval(user.tokenInfo, this.refreshTokenCheckInterval);
          //const missingSecondsToExpire = this.authenticationTokenService.missingSecondsToExpire(user.tokenInfo);
          //console.log('Token - expired ' + expired + ' will expire ' + willExpire + ' will expire in: ' + missingSecondsToExpire + ' exp d ' + user.tokenInfo.expD + ' cdate ' + new Date().toISOString());
          if (willExpire) {
            this.refreshUser();
          }
        }
      });
    }
  }

  getUser(): UserAuthModel | null {
    const userData = this.storageService.get(this.userTokenKey);
    if (userData) {
      const user: UserAuthModel = JSON.parse(userData);
      //    user.tokenInfo = this.authenticationTokenService.createTokenInfo(user.token.access_token);

      return user;
    }
    return null;
  }


  public refreshUser(): void {
    const user: UserAuthModel = this.getUser();
    if (!user) {
      //avisa interessados que não há usuário logado
      this.userSubject.next(null);
    } else {
      //atualiza dados + token do usuário corrente
      //console.log('refreshing user ' + new Date().toISOString());
      this.httpClient
        .executeGet<UserAuthModel>(this.getUserRefreshTokenUrl())
        .subscribe(
          {
            next: (user: UserAuthModel) => {
              //console.log('setting user ' + new Date().toISOString());
              this.setUser(user);
            },
            error: (error) => {

              const willExpire = this.authenticationTokenService.willExpireOnNextInterval(user.tokenInfo, this.refreshTokenCheckInterval);
              //const missingSecondsToExpire = this.authenticationTokenService.missingSecondsToExpire(user.tokenInfo);
              //console.log('Token - expired ' + expired + ' will expire ' + willExpire + ' will expire in: ' + missingSecondsToExpire + ' exp d ' + user.tokenInfo.expD + ' cdate ' + new Date().toISOString());
              if (willExpire || error?.status === 400 || error?.status === 401) {
                this.logout();
              }
            }
          }
        );
    }
  }

  public requestNewEmailConfirmationCode(email: string) {
    return this.httpClient.executePost<UserAuthModel>(null, `/identity/request-new-email-code/${email}`);
  }

  public confirmEmail(model: EmailConfirmationModel) {
    return this.httpClient.executePost<UserAuthModel>(model, `/identity/email-confirm`);
  }

  public requestEmailResetPassword(email: string) {
    return this.httpClient.executePost<UserAuthModel>(null, `/identity/request-email-reset-password/${email}`);
  }



  public resetPassword(model: ResetPasswordModel) {
    return this.httpClient.executePost<UserAuthModel>(model, `/identity/reset-email-password`);
  }


  public generateQR(email: string) {
    return this.httpClient.executeGet<OperationResult<string>>(`/identity/generateqr?email=${email}`);
  }

  public validateAuthenticatorCode(code: string) {
    return this.httpClient.executePost<OperationResult<boolean>>({}, `/identity/validatecode/${code}`);
  }

  public hasRole(role: string | string[]): boolean {
    const user = this.getUser();
    if (!user.tokenInfo?.role?.length) return false;
    if (Array.isArray(role)) {
      return user.tokenInfo.role.some(tokenRole => role.some(roleItem => roleItem && tokenRole && roleItem.toLowerCase() === tokenRole.toLowerCase()));
    }

    return user.tokenInfo.role.some(tokenRole => tokenRole === role);
  }

  public hasClaim(claimName: string | string[]): boolean {
    const user = this.getUser();
    if (!user.tokenInfo) return false;

    if (Array.isArray(claimName)) {
      return claimName.some(claimItem => claimItem && user.tokenInfo.hasOwnProperty(claimItem as keyof TokenInfoModel));
    }

    const doesItHas = user.tokenInfo.hasOwnProperty(claimName as keyof TokenInfoModel);
    return doesItHas;
  }

  public getClaimValue(claimName: string) {
    const user = this.getUser();
    if (!user.tokenInfo) return null;
    return user.tokenInfo[claimName as keyof TokenInfoModel];
  }
}
