import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CountryModel } from "../../../../shared/models/addressing/country-model";
import { CountryService } from "../../../../shared/services/addressing/country.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";

@Component({
    selector: "app-country-item",
    templateUrl: "./country-item.component.html",
    styleUrls: ["./country-item.component.scss"]
})
export class CountryItemComponent implements OnInit {
    @Input() model: CountryModel;
    @Output() savedITem = new EventEmitter<CountryModel>();
    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private service: CountryService,
        private translateService: TranslateService,
        private messageService: MessageService

    ) {
        this.model = this.config.data.model;
    }

    save(form: NgForm) {

        if (form.valid) {
            this.translateService.get(['operations.save.summary', 'operations.save.details']).subscribe((messages) => {
                const summaryMessage = messages['operations.save.summary'];
                const detailsMessage = messages['operations.save.details'];

                const subscription = this.service.save(this.model).subscribe({
                    next: (result) => {

                        this.savedITem.emit(result.item);
                        this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
                    },
                    complete: () => { subscription.unsubscribe(); this.close(); }
                });
            });
        }
    }

    close() {
        this.ref?.close();
    }


    ngOnInit(): void {

    }


}
