import { PhysicalLocationModel } from "../../addressing/physical-location-model";
import { PostalCodeModel } from "../../addressing/postal-code-model";
import { NamedBaseModelInt } from "../../infra/named-model";
import { PersonModel } from "./person-model";

export class PersonContactAddressModel extends NamedBaseModelInt {
    constructor(other?: PersonContactAddressModel | undefined) {
        super(other);
        if (other) {
            this.person = other.person ? new PersonModel(other.person) : other.person;
            this.physicalLocation = other.physicalLocation ? new PhysicalLocationModel(other.physicalLocation) : other.physicalLocation;

            this.physicalLocationId = other.physicalLocationId;
            this.personId = other.personId;
            this.postalCode = other.postalCode ? new PostalCodeModel(other.postalCode) : other.postalCode;
            this.postalCodeId = other.postalCodeId;
            this.complement = other.complement;
            this.number = other.number;
        }
    }

    public personId: number = 0;
    public person: PersonModel = null;
    public physicalLocationId: number;
    public physicalLocation: PhysicalLocationModel = null;
    public postalCodeId: number;
    public postalCode: PostalCodeModel;
    public complement: string = null;
    //to accept any value
    public number: string = null;
}
