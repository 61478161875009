import { BaseModelInt } from "../../infra/base-model";


export class AppFrontConfigsModel extends BaseModelInt {
    constructor(other?: AppFrontConfigsModel | undefined) {
        super(other);
        if (other) {
            this.userId = other.userId;
            this.tablePageSize = other.tablePageSize;
            this.scheduleHourSplitPieces = other.scheduleHourSplitPieces;
            this.yongerThanRequiresBastion = other.yongerThanRequiresBastion;
            this.defaultClientIdentifierType = other.defaultClientIdentifierType;
            this.closeScheduledOrderAfter = other.closeScheduledOrderAfter;
            this.closeScheduledOrderStatus = other.closeScheduledOrderStatus;
            this.issuePasswordByPriority = other.issuePasswordByPriority;
            this.issuePasswordByServiceOrderType = other.issuePasswordByServiceOrderType;
        }
    }

    public userId: number;
    public tablePageSize: number = 10;
    public scheduleHourSplitPieces: number = 2;
    public yongerThanRequiresBastion: number = 0;
    public closeScheduledOrderAfter: number;
    public closeScheduledOrderStatus: number;
    public issuePasswordByPriority: boolean;
    public issuePasswordByServiceOrderType: boolean;
    public defaultClientIdentifierType: KnownClientIdentifierType;
}


export enum KnownClientIdentifierType {
    CPF,
    EMAIL,
    PHONE,
}

