import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../../../shared/services/identity/authentication.service';
import { LoginModel } from '../../../shared/models/request/identity/login-model';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs';
import { CredentialResponse } from 'google-one-tap';
import { AuthenticationTokenService } from '../../../shared/services/identity/authentication-token';
import { LoginExternalModel } from 'src/app/modules/shared/models/request/identity/login-external-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  rememberMe = false;
  submitted: boolean = false;
  model = new LoginModel();
  @ViewChild('googleButton', { static: true }) googleButton: ElementRef = new ElementRef({});



  constructor(
    protected service: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private authenticationTokenService: AuthenticationTokenService,
    private router: Router,
    private _renderer2: Renderer2,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.service.user$.pipe(take(1)).subscribe({
      next: (user) => {
        if (user) {
          this.router.navigateByUrl('/');
        } else {

        }
      }
    })


  }

  ngOnInit(): void {
    this.initiazeGoogleButton();
  }

  ngOnDestroy(): void {

  }
  ngAfterViewInit() {
    const script1 = this._renderer2.createElement('script');
    script1.src = 'https://accounts.google.com/gsi/client';
    script1.async = 'true';
    script1.defer = 'true';
    this._renderer2.appendChild(this._document.body, script1);
  }

  login(form: NgForm): void {
    this.submitted = true;

    if (form.valid) {

      const subscription = this.service.login(this.model).subscribe({
        next: () => {
          this.router.navigateByUrl('/');
        },
        complete: () => {
          subscription.unsubscribe();
          this.submitted = false;
        }
      });
    }

  }

  private initiazeGoogleButton() {
    (window as any).onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: environment.GoogleClientApp.ClientId,
        callback: this.googleCallBack.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        this.googleButton.nativeElement,
        { size: 'medium', shape: 'rectangular', text: 'signin_with', logo_alignment: 'center' }
      );
    };
  }

  private async googleCallBack(response: CredentialResponse) {
    this.ngZone.run(() => {
      const decodedToken: any = JSON.parse(this.authenticationTokenService.decodeJwtPayload(response.credential));
      const externalLogin = new LoginExternalModel({ accessToken: response.credential, userId: decodedToken.sub, provider: "google", email: decodedToken.email });
      const subscription = this.service.loginExternal(externalLogin).subscribe({
        next: () => {
          this.router.navigateByUrl('/');
        },
        complete: () => {
          subscription.unsubscribe();
          this.submitted = false;
        }
      });
    });
  }
}
