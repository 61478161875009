import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from './modules/shared/services/identity/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserCompanySectorsService } from './modules/shared/services/business/user-company-sectors.service';
import { AppConfig, LayoutService } from './modules/layout/service/app.layout.service';
import { AppFrontConfigsModel } from './modules/shared/models/medicius/appstuffs/app-front-configs-model';
import { AppFrontConfigsService } from './modules/shared/services/medicius/appstuffs/app-front-configs.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private config: PrimeNGConfig,
        private translateService: TranslateService,
        private authService: AuthenticationService,
        private userCompanySectorsService: UserCompanySectorsService,
        private appFrontConfigsService: AppFrontConfigsService,
        private activatedRoute: ActivatedRoute,
        private layoutService: LayoutService,
        private router: Router) { }

    ngOnInit() {
        var language = 'pt-BR';
        this.config.ripple = true;
        this.translateService.setDefaultLang(language);
        this.translateService.addLangs([language]);
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
        this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));



        //optional configuration with the default configuration
        const config: AppConfig = {
            ripple: false,                      //toggles ripple on and off
            inputStyle: 'outlined',             //default style for input elements
            menuMode: 'static',                 //layout mode of the menu, valid values are "static" and "overlay"
            colorScheme: 'light',               //color scheme of the template, valid values are "light" and "dark"
            theme: 'lara-light-indigo',         //default component theme for PrimeNG
            scale: 13                           //size of the body font size to scale the whole application
        };
        this.layoutService.config.set(config);


        this.router.events.subscribe((routerEvent) => {
            // const str = routerEvent.toString()
            if (routerEvent.type === 15) {
                if (routerEvent.routerEvent?.['urlAfterRedirects'] == '/public' && this.authService.isAuthenticated()) {
                    this.router.navigateByUrl('/medicius');
                }
            }
            //console.log(str);
        });

        this.authService.user$.subscribe((user) => {
            var currentUrl = window.location.pathname;
            if (!user || !user.token || !user.email) {
                if (!currentUrl.includes('identity') && !currentUrl.includes("confirm"))
                    this.router.navigateByUrl('/');
            } else {
                const subscription = this.userCompanySectorsService.getMyCompanies().subscribe({
                    next: (result) => {

                        if (currentUrl == '/public') {
                            this.router.navigateByUrl('/medicius');
                        }
                        //information will be send to interested by myCompanies$
                    },
                    complete: () => { subscription.unsubscribe(); }
                });

                this.appFrontConfigsService.getAll().subscribe();

            }

        });

        this.authService.refreshUser();


    }

    translate(lang: string) {

    }



}
