import { NamedBaseModelInt } from "../infra/named-model";
import { ProductImageModel } from "./product-image-model";

export class ProductModel extends NamedBaseModelInt {
    constructor(other?: ProductModel | undefined) {
        super(other);
        if (other) {
            this.enabled = other.enabled;
            this.productImages = other.productImages ? other.productImages.map(x => new ProductImageModel(x)) : other.productImages;
        }

    }

    public productImages: ProductImageModel[] = [];



    public enabled: boolean = true;
}
