import { BaseModelInt } from "../infra/base-model";
import { NamedBaseModelInt } from "../infra/named-model";
import { CallIdStatusModel } from "./call-id-status-model";
import { PriorityTypeModel } from "./priority-type-model";
import { ProductModel } from "./product-model";
import { ServiceOrderTypeModel } from "./service-records/service-order-type-model";
import { CompanyLocationModel } from './comercial/company-location-model';
import { ServiceOrderModel } from "./service-records/service-order-model";

export class CallIdModel extends BaseModelInt {
    constructor(other?: CallIdModel | undefined) {
        super(other);
        if (other) {
            this.date = other.date;
            this.todayId = other.todayId;
            this.externalIdentifier = other.externalIdentifier;
            this.serviceOrderTypeId = other.serviceOrderTypeId;
            this.priorityTypeId = other.priorityTypeId;
            this.callIdStatusId = other.callIdStatusId;
            this.displayValue = other.displayValue;
            this.companyLocationId = other.companyLocationId;

            this.serviceOrderType = other.serviceOrderType != null ? new ServiceOrderTypeModel(other.serviceOrderType) : other.serviceOrderType;
            this.priorityType = other.priorityType != null ? new PriorityTypeModel(other.priorityType) : other.priorityType;
            this.callIdStatus = other.callIdStatus != null ? new CallIdStatusModel(other.callIdStatus) : other.callIdStatus;
            this.companyLocation = other.companyLocation != null ? new CompanyLocationModel(other.companyLocation) : other.companyLocation;
            this.serviceOrder = other.serviceOrder ? new ServiceOrderModel(other.serviceOrder) : other.serviceOrder;
        }
    }

    public date: Date;

    public todayId: number;

    public externalIdentifier: string;

    public displayValue: string;


    public serviceOrderTypeId: number;
    public serviceOrderType: ServiceOrderTypeModel;

    public serviceOrder: ServiceOrderModel;


    public priorityTypeId: number;
    public priorityType: PriorityTypeModel;


    public callIdStatusId: number;
    public callIdStatus: CallIdStatusModel;

    public companyLocationId: number;
    public companyLocation: CompanyLocationModel;

}

