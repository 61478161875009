import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/identity/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { EmailModel } from '../../../shared/models/request/identity/email-model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent implements OnInit {

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  model = new EmailModel();

  constructor(protected service: AuthenticationService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
  }




  requestPasswordReset(form: NgForm): void {
    this.submitted = true;

    if (form.valid) {

      this.translateService.get(['operations.passwordResetRequest.success.sent.header', 'operations.passwordResetRequest.success.sent.body']).subscribe((messages) => {
        const header = messages['operations.passwordResetRequest.success.sent.header'];
        const body = messages['operations.passwordResetRequest.success.sent.body'];

        const subscription = this.service.requestEmailResetPassword(this.model.email).subscribe({
          next: (result) => {
            this.confirmationService.confirm({
              message: body,
              header: header,
              key: 'requestPassword',
              icon: 'pi pi-exclamation-triangle',
              rejectVisible: false,
              accept: () => {
                this.router.navigate(['../login'], { relativeTo: this.activatedRoute });
              }
            });
          },
          complete: () => { subscription.unsubscribe(); this.submitted = false; }
        });

      });
    }
  }



}
