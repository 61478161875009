import { NamedBaseModelInt } from "../infra/named-model";
import { CityModel } from "./city-model";

export class NeighborhoodModel extends NamedBaseModelInt {
  constructor(other?: NeighborhoodModel) {
    super(other);
    if (other) {
      this.city = other.city ? new CityModel(other.city) : new CityModel();
      this.cityId = other.cityId;
    }
  }


  public cityId: number = 0;

  private _city: CityModel;
  public get city(): CityModel {
    return this._city;
  }
  public set city(v: CityModel) {
    this._city = v;
    if (v) {
      this.cityId = v.id;
    }
  }
}
