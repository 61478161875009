import { NamedBaseModelInt } from "../../infra/named-model";

export class PersonRelationshipDegreeTypeModel extends NamedBaseModelInt {
    constructor(other?: PersonRelationshipDegreeTypeModel | undefined) {
        super(other);
        if (other) {

        }
    }
}
