import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../shared/services/identity/authentication.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[] = [];
    //t: MenuItem;
    constructor(public layoutService: LayoutService, private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.authenticationService.user$.subscribe((user) => {
            if (!user) {
                this.model = [
                    {
                        label: 'Área pública',
                        icon: 'pi pi-fw pi-id-card',
                        expanded: false,
                        roles: ['PUBLIC'],
                        items: [
                            {
                                label: 'Plano',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/public/planschedule'],
                                roles: ['PUBLIC'],
                            },

                        ],
                    },
                ];
            } else {
                this.model = [
                    {
                        label: 'Atendimento Agendado',
                        icon: 'pi pi-fw pi-clipboard',
                        claims: ['Professional'],
                        items: [

                            {
                                label: 'Clientes',
                                icon: 'pi pi-fw pi-id-card',
                                claims: ['Professional'],
                                routerLink: ['/medicius/clients']
                            },
                            {
                                label: 'Minha agenda',
                                icon: 'pi pi-fw pi-id-card',
                                //routerLink: ['/medicius/settings/myschedule'],
                                claims: ['Professional'],
                                expanded: true,
                                items: [
                                    {
                                        label: 'Dia',
                                        icon: 'pi pi-fw pi-id-card',
                                        routerLink: ['/medicius/settings/myschedule/day'],
                                        claims: ['Professional'],
                                        // queryParams: { 'type': 'day' }
                                    },
                                    {
                                        label: 'Semana',
                                        icon: 'pi pi-fw pi-id-card',
                                        routerLink: ['/medicius/settings/myschedule/week'],
                                        claims: ['Professional'],
                                        //queryParams: { 'type': 'week' }
                                    },
                                    {
                                        label: 'Mês',
                                        icon: 'pi pi-fw pi-id-card',
                                        routerLink: ['/medicius/settings/myschedule/month'],
                                        claims: ['Professional'],
                                        //queryParams: { 'type': 'month' }

                                    },
                                ]
                            },
                            {
                                label: 'Criar Compromisso',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/scheduledserviceorders/serviceordertype'],
                                claims: ['Professional'],
                            },
                            {
                                label: 'Compromissos Agendados',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/scheduledserviceorders/list'],
                                claims: ['Professional'],
                            },

                        ]
                    },
                    {
                        label: 'Pronto Atendimento',
                        icon: 'pi pi-fw pi-clipboard',
                        items: [
                            {
                                label: 'Gerar senha de atendimento',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/preserviceorder'],
                                claims: ['Professional'],
                            },
                            {
                                label: 'Fila Atendimento',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/preserviceorderqueue'],
                                claims: ['Professional'],
                            },
                            {
                                label: 'Chamadas Atendimento',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/preserviceordercalls'],
                                claims: ['Professional'],
                            },
                            {
                                label: 'Registrados',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/serviceorders'],
                                claims: ['Professional'],
                            }
                            // ,
                            // {
                            //     label: 'Fila do meu setor',
                            //     icon: 'pi pi-fw pi-id-card',
                            //     routerLink: ['/medicius/mysectorserviceorderqueues']
                            // },

                        ]
                    },
                    {
                        label: 'Cadastros básicos',
                        icon: 'pi pi-fw pi-clipboard',
                        claims: ['Professional'],
                        items: [
                            {
                                label: 'Pessoas',
                                icon: 'pi pi-fw pi-clipboard',
                                claims: ['Professional'],
                                items: [
                                    {
                                        label: 'Sexo',
                                        routerLink: ['/medicius/personal/gendertypes'],
                                        claims: ['Professional'],
                                    },
                                    {
                                        label: 'Gênero',
                                        routerLink: ['/medicius/personal/socialgendertypes'],
                                        claims: ['Professional'],
                                    },
                                    {
                                        label: 'Raça',
                                        routerLink: ['/medicius/personal/racetypes'],
                                        claims: ['Professional'],
                                    }

                                    // ,
                                    // {
                                    //     label: 'Prioridades',
                                    //     routerLink: ['/medicius/personal/priorities'],
                                    //     claims: ['Professional'],
                                    // }
                                ]
                            },
                            {
                                label: 'Ordem de serviço',
                                icon: 'pi pi-fw pi-clipboard',
                                claims: ['Professional'],
                                items: [
                                    {
                                        label: 'Prioridade de atendimento',
                                        routerLink: ['/medicius/settings/serviceorderprioritytypes'],
                                        claims: ['Professional'],
                                    },
                                    {
                                        label: 'Tipos de Ordens de Serviço (OS)',
                                        routerLink: ['/medicius/settings/servicerordertypes'],
                                        claims: ['Professional'],
                                    },
                                    {
                                        label: 'Status Agendamento (OS)',
                                        routerLink: ['/medicius/settings/scheduledservicerorderstatus'],
                                        claims: ['Professional'],
                                    },
                                    {
                                        label: 'Notificações de OS',
                                        tooltip: 'Notificações enviadas aos clientes ao registrar ou alterar uma OS (Ordem de serviço)',
                                        routerLink: ['/medicius/settings/scheduledservicerordernotification'],
                                        claims: ['Professional'],
                                    },
                                ]
                            },
                            {
                                label: 'Outros',
                                icon: 'pi pi-fw pi-clipboard',
                                claims: ['Professional'],
                                items: [
                                    {
                                        label: 'Especialidades',
                                        routerLink: ['/medicius/personal/specialties'],
                                        claims: ['Professional'],
                                    },
                                ]
                            },

                        ]
                    },
                    {
                        label: 'Configurações',
                        icon: 'pi pi-fw pi-clipboard',
                        claims: ['Professional'],
                        items: [
                            {
                                label: 'Meus horários',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/settings/myworkday']
                            },
                            {
                                label: 'Meus dados',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/settings/mydata']
                            },
                            {
                                label: 'Minhas especialidades',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/settings/myspecialties']
                            },
                            {
                                label: 'Configurações do app',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/settings/appfrontconfigs']
                            },
                            {
                                label: 'Meu plano',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/medicius/settings/myplan']
                            },


                        ]
                    },



                ];
            }
        });







        // this.model = [
        //     {
        //         label: 'Cadastros gerais2 ',
        //         icon: 'pi pi-fw pi-id-card',
        //         expanded: false,
        //         roles: ['PUBLIC'],
        //         items: [
        //             {
        //                 label: 'Quem somos',
        //                 icon: 'pi pi-fw pi-id-card',
        //                 routerLink: ['/public/planschedule'],
        //                 roles: ['PUBLIC'],

        //             },
        //             {
        //                 label: 'Plano',
        //                 icon: 'pi pi-fw pi-id-card',
        //                 routerLink: ['/public/planschedule'],
        //                 roles: ['PUBLIC'],
        //             },

        //         ],
        //     },
        //     {
        //         label: 'Cadastros básicos 2222',
        //         icon: 'pi pi-fw pi-clipboard',
        //         claims: ['Professional'],
        //         items: [
        //             {
        //                 label: 'Pessoas',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 claims: ['Professional'],
        //                 items: [
        //                     {
        //                         label: 'Sexo',
        //                         routerLink: ['/medicius/personal/gendertypes'],
        //                         claims: ['Professional'],
        //                     },
        //                     {
        //                         label: 'Gênero',
        //                         routerLink: ['/medicius/personal/socialgendertypes'],
        //                         claims: ['Professional'],
        //                     },
        //                     {
        //                         label: 'Raça',
        //                         routerLink: ['/medicius/personal/racetypes'],
        //                         claims: ['Professional'],
        //                     }

        //                     // ,
        //                     // {
        //                     //     label: 'Prioridades',
        //                     //     routerLink: ['/medicius/personal/priorities'],
        //                     //     claims: ['Professional'],
        //                     // }
        //                 ]
        //             },
        //             {
        //                 label: 'Substâncias e medicamentos',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 roles: ['ADMIN'],
        //                 items: [

        //                     {
        //                         label: 'Classes Terapêuticas',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/therapeuticclasstype'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Formas de administração',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/medicineadministrationtype'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Formas farmacêuticas',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/pharmaceuticalformtype'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Subformas farmacêuticas',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/pharmaceuticalsubformtype'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Controle de Medicação',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/medicinecontroltype'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Classificações P. Ativo',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/activeprincipleclassification'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Unidades de medida',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/unitmeasure'],
        //                         roles: ['ADMIN']
        //                     },
        //                 ]
        //             },

        //             {
        //                 label: 'Alergias e Intolerâncias',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 roles: ['ADMIN'],
        //                 items: [
        //                     {
        //                         label: 'Tipos de alergias',
        //                         routerLink: ['/medicius/alergy/alergytypes'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Alergias',
        //                         routerLink: ['/medicius/alergy/alergies'],
        //                         roles: ['ADMIN']
        //                     },

        //                 ]
        //             },
        //             {
        //                 label: 'Corporativos',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 roles: ['ADMIN'],
        //                 items: [
        //                     {
        //                         label: 'Tipos de Estabelecimentos',
        //                         routerLink: ['/medicius/companydeps/cnestypes'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Status Ordem de Serviço',
        //                         routerLink: ['/medicius/services/serviceorderstatuses'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Status Ag. Ordem de Serviço',
        //                         routerLink: ['/medicius/services/scheduledserviceorderstatuses'],
        //                         roles: ['ADMIN']
        //                     },
        //                 ]
        //             },



        //         ]
        //     },


        //     {

        //         label: 'Cadastros gerais',
        //         icon: 'pi pi-fw pi-id-card',
        //         expanded: false,
        //         items: [
        //             {
        //                 label: 'Cadastros pessoais',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 items: [
        //                     // {
        //                     //     label: 'Pessoas',
        //                     //     icon: 'pi pi-fw pi-id-card',
        //                     //     routerLink: ['/medicius/persons']
        //                     // },
        //                     {
        //                         label: 'Clientes',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/clients']
        //                     },
        //                     {
        //                         label: 'Profissionais',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/professionals']
        //                     },

        //                 ]
        //             },
        //             {
        //                 label: 'Cadastros corporativos',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 items: [
        //                     {
        //                         label: 'Empresas',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/companies']
        //                     },
        //                     {
        //                         label: 'Setores x Empresas',
        //                         icon: 'pi pi-fw pi-box',
        //                         routerLink: ['/medicius/sectors']
        //                     },
        //                     {
        //                         label: 'Setores x Ativos',
        //                         icon: 'pi pi-fw pi-box',
        //                         routerLink: ['/medicius/sectorsxassets']
        //                     },
        //                     {
        //                         label: 'Ativos',
        //                         icon: 'pi pi-fw pi-box',
        //                         routerLink: ['/medicius/assets']
        //                     },
        //                 ]
        //             },
        //             {
        //                 label: 'Classificação de Riscos',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 items: [
        //                     {
        //                         label: 'Categorias de risco',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/riskcategories']
        //                     },
        //                     {
        //                         label: 'Níveis de risco',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/risklevels']
        //                     },


        //                 ]
        //             },
        //             {
        //                 label: 'Pronto Atendimento',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 items: [
        //                     {
        //                         label: 'Pré Atendimento',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/preserviceorder']
        //                     },
        //                     {
        //                         label: 'Fila Pré Atendimento',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/preserviceorderqueue']
        //                     },
        //                     {
        //                         label: 'Chamadas Atendimento',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/preserviceordercalls']
        //                     },
        //                     {
        //                         label: 'Registrados',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/serviceorders']
        //                     },
        //                     {
        //                         label: 'Filas minha empresa',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/mycompanyserviceorderqueues']
        //                     },
        //                     {
        //                         label: 'Fila do meu setor',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/mysectorserviceorderqueues']
        //                     },

        //                 ]
        //             },
        //             {
        //                 label: 'Agendamento',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 items: [
        //                     {
        //                         label: 'Consultas Agendadas',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/scheduledserviceorders/list']
        //                     },
        //                     {
        //                         label: 'Consultas',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/scheduledserviceorders/appointment']
        //                     },
        //                     {
        //                         label: 'Exame',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/scheduledserviceorders/exam']
        //                     },
        //                     {
        //                         label: 'Procedimento',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/scheduledserviceorders/procedure']
        //                     },
        //                 ]
        //             },
        //             {
        //                 label: 'Substâncias e Medicamentos',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 items: [
        //                     {
        //                         label: 'Medicamentos',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         routerLink: ['/medicius/substance/medicine'],
        //                         roles: ['ADMIN'],
        //                     },
        //                     {
        //                         label: 'Princípios ativos',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         routerLink: ['/medicius/substance/activeprinciple'],
        //                         roles: ['ADMIN'],
        //                     },
        //                     {
        //                         label: 'Tipos de Concentração',
        //                         icon: 'pi pi-fw pi-id-card',
        //                         routerLink: ['/medicius/substance/concentrationtype'],
        //                         roles: ['ADMIN'],
        //                     },

        //                 ]
        //             },


        //             {
        //                 label: "Administração de acesso",
        //                 icon: "pi pi-fw pi-lock-open",
        //                 roles: ['ADMIN'],
        //                 items: [
        //                     {
        //                         label: 'Usuários',
        //                         icon: 'pi pi-fw pi-users',
        //                         routerLink: ['/access/users'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Usuário x Grupos',
        //                         icon: 'pi pi-fw pi-users',
        //                         routerLink: ['/access/users/roles'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Grupos',
        //                         icon: 'pi pi-fw pi-unlock',
        //                         routerLink: ['/access/roles'],
        //                         roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Grupo x Usuários',
        //                         icon: 'pi pi-fw pi-unlock',
        //                         routerLink: ['/access/roles/users'],
        //                         roles: ['ADMIN']
        //                     },
        //                 ]
        //             },
        //             {
        //                 label: 'Localizações',
        //                 icon: 'pi pi-fw pi-map',
        //                 roles: ['ADMIN', 'ADDRESS_OPERATOR'],
        //                 items: [
        //                     {
        //                         label: 'Países',
        //                         icon: 'pi pi-fw pi-map-marker',
        //                         routerLink: ['/addressing/countries'],
        //                         roles: ['ADMIN', 'ADDRESS_OPERATOR']
        //                     },
        //                     {
        //                         label: 'Estados',
        //                         icon: 'pi pi-fw pi-map-marker',
        //                         routerLink: ['/addressing/states'],
        //                         roles: ['ADMIN', 'ADDRESS_OPERATOR']
        //                     },
        //                     {
        //                         label: 'Cidades',
        //                         icon: 'pi pi-fw pi-map-marker',
        //                         routerLink: ['/addressing/cities'],
        //                         roles: ['ADMIN', 'ADDRESS_OPERATOR']
        //                     },
        //                     {
        //                         label: 'Bairros',
        //                         icon: 'pi pi-fw pi-map-marker',
        //                         routerLink: ['/addressing/neighborhoods'],
        //                         roles: ['ADMIN', 'ADDRESS_OPERATOR']
        //                     },
        //                     {
        //                         label: 'Logradouros',
        //                         icon: 'pi pi-fw pi-map-marker',
        //                         routerLink: ['/addressing/placetypes'],
        //                         roles: ['ADMIN', 'ADDRESS_OPERATOR']
        //                     },
        //                     {
        //                         label: 'Endereços',
        //                         icon: 'pi pi-fw pi-map-marker',
        //                         routerLink: ['/addressing/physicallocations'],
        //                         roles: ['ADMIN', 'ADDRESS_OPERATOR']
        //                     }
        //                 ]
        //             },
        //             {
        //                 label: 'Cadastros básicos',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 items: [
        //                     {
        //                         label: 'Pessoas',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         items: [
        //                             {
        //                                 label: 'Sexo',
        //                                 routerLink: ['/medicius/personal/gendertypes'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Gênero',
        //                                 routerLink: ['/medicius/personal/socialgendertypes'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Raça',
        //                                 routerLink: ['/medicius/personal/racetypes'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Prioridades',
        //                                 routerLink: ['/medicius/personal/priorities'],
        //                                 roles: ['ADMIN']
        //                             }
        //                         ]
        //                     },
        //                     {
        //                         label: 'Substâncias e medicamentos',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         roles: ['ADMIN'],
        //                         items: [

        //                             {
        //                                 label: 'Classes Terapêuticas',
        //                                 icon: 'pi pi-fw pi-id-card',
        //                                 routerLink: ['/medicius/substance/therapeuticclasstype'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Formas de administração',
        //                                 icon: 'pi pi-fw pi-id-card',
        //                                 routerLink: ['/medicius/substance/medicineadministrationtype'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Formas farmacêuticas',
        //                                 icon: 'pi pi-fw pi-id-card',
        //                                 routerLink: ['/medicius/substance/pharmaceuticalformtype'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Subformas farmacêuticas',
        //                                 icon: 'pi pi-fw pi-id-card',
        //                                 routerLink: ['/medicius/substance/pharmaceuticalsubformtype'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Controle de Medicação',
        //                                 icon: 'pi pi-fw pi-id-card',
        //                                 routerLink: ['/medicius/substance/medicinecontroltype'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Classificações P. Ativo',
        //                                 icon: 'pi pi-fw pi-id-card',
        //                                 routerLink: ['/medicius/substance/activeprincipleclassification'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Unidades de medida',
        //                                 icon: 'pi pi-fw pi-id-card',
        //                                 routerLink: ['/medicius/substance/unitmeasure'],
        //                                 roles: ['ADMIN']
        //                             },
        //                         ]
        //                     },

        //                     {
        //                         label: 'Alergias e Intolerâncias',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         roles: ['ADMIN'],
        //                         items: [
        //                             {
        //                                 label: 'Tipos de alergias',
        //                                 routerLink: ['/medicius/alergy/alergytypes'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Alergias',
        //                                 routerLink: ['/medicius/alergy/alergies'],
        //                                 roles: ['ADMIN']
        //                             },

        //                         ]
        //                     },
        //                     {
        //                         label: 'Corporativos',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         roles: ['ADMIN'],
        //                         items: [
        //                             {
        //                                 label: 'Tipos de Estabelecimentos',
        //                                 routerLink: ['/medicius/companydeps/cnestypes'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Status Ordem de Serviço',
        //                                 routerLink: ['/medicius/services/serviceorderstatuses'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Status Ag. Ordem de Serviço',
        //                                 routerLink: ['/medicius/services/scheduledserviceorderstatuses'],
        //                                 roles: ['ADMIN']
        //                             },
        //                         ]
        //                     },



        //                 ]
        //             },
        //             {
        //                 label: 'Desenvolvimento',
        //                 icon: 'pi pi-fw pi-clipboard',
        //                 // roles: ['ADMIN'],
        //                 items: [
        //                     {
        //                         label: 'Profissional',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         routerLink: ['/public/professionalflow'],
        //                         //  roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Planos',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         routerLink: ['/medicius/admin/plan'],
        //                         //  roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Features',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         routerLink: ['/medicius/admin/feature'],
        //                         //  roles: ['ADMIN']
        //                     },

        //                     {
        //                         label: 'Grupos de Features',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         routerLink: ['/medicius/admin/featuregroup'],
        //                         //  roles: ['ADMIN']
        //                     },
        //                     {
        //                         label: 'Bancos dados',
        //                         icon: 'pi pi-fw pi-clipboard',
        //                         items: [
        //                             {
        //                                 label: 'Módulos',
        //                                 routerLink: ['/metadata/database/modules'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Componentes',
        //                                 routerLink: ['/metadata/database/components'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Componentes no banco',
        //                                 routerLink: ['/metadata/database/database-components'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Provedores',
        //                                 routerLink: ['/metadata/database/providers'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Tipos de dados de Provedores',
        //                                 routerLink: ['/metadata/database/providertypes'],
        //                                 roles: ['ADMIN']
        //                             },
        //                             {
        //                                 label: 'Tipos de dados internos',
        //                                 routerLink: ['/metadata/database/internaltypes'],
        //                                 roles: ['ADMIN']
        //                             },

        //                         ]
        //                     },
        //                 ]
        //             },
        //         ]
        //     }


        // ];
    }
}
