import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Table } from "primeng/table";

import { StateItemComponent } from "../state/state-item/state-item.component";
import { CityModel } from '../../../shared/models/addressing/city-model';
import { CountryModel } from '../../../shared/models/addressing/country-model';
import { CityService } from "../../../shared/services/addressing/city.service";
import { CountryService } from '../../../shared/services/addressing/country.service';
import { StateService } from '../../../shared/services/addressing/state.service';
import { StateModel } from "src/app/modules/shared/models/addressing/state-model";
import { CityItemComponent } from "./city-item/city-item.component";


@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss'],
})
export class CityComponent implements OnInit {
  models: CityModel[] = [];
  selectedItems!: CityModel[] | null;
  countries: CountryModel[] | undefined;
  selectedCountry: CountryModel;
  filteredCountries: CountryModel[] | undefined;
  states: StateModel[] | undefined;
  selectedState: StateModel;
  filteredStates: StateModel[] | undefined;

  constructor(
    private translateService: TranslateService,
    private service: CityService,
    private countryService: CountryService,
    private stateService: StateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.loadCountries();

  }

  loadCountries() {
    this.countries = [];
    const subscription = this.countryService.getAll().subscribe({
      next: (result) => {
        this.countries = result.items;
        this.filterCountry("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  loadStates(countryId: number) {
    this.states = [];
    const subscription = this.stateService.getAllByCountryId(countryId).subscribe({
      next: (result) => {
        this.states = result.items;
        this.filterState("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  load(stateId: number) {
    this.models = [];
    const subscription = this.service.getAllByStateId(stateId).subscribe({
      next: (result) => {
        this.models = result.items;
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  openRegistry(model: CityModel) {

    let modelParam = model ? model : new CityModel();
    if (this.selectedState && model) {
      modelParam.state = this.selectedState;
    }

    const ref = this.dialogService.open(CityItemComponent, {
      data: { model: modelParam, countries: this.countries, selectedCountry: this.selectedCountry, selectedState: this.selectedState },
      width: '50%',
      contentStyle: { overflow: 'hidden' },
      appendTo: 'body',
      modal: true,
      showHeader: false,
      closeOnEscape: false,
      closable: false,
    });

    ref?.onClose.subscribe(() => {
      if (!this.selectedState) {
        if (modelParam.state?.id > 0) {
          this.onCountrySelect(modelParam.state.country);
          this.onStateSelected(modelParam.state);
        }
      } else {
        this.load(this.selectedState.id);
      }
    });
  }

  deleteSelectedItems() {
    this.translateService.get(['operations.delete.items', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details']).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.items'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        icon: 'pi pi-exclamation-triangle',
        key: 'delete',
        accept: () => {
          const subscription = this.service.delete(this.selectedItems).subscribe({
            next: (result) => {
              //this.models = this.models.filter((val) => !this.selectedItems?.includes(val));
              this.load(this.selectedState.id);
              this.selectedItems = null;
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });
  }

  editModel(model: CityModel) {
    this.openRegistry(new CityModel(model))
  }

  deleteModel(model: CityModel) {
    this.translateService.get(['operations.delete.item', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details'], { name: model.name }).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.item'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        icon: 'pi pi-exclamation-triangle',
        key: 'delete',
        accept: () => {
          const subscription = this.service.delete(model).subscribe({
            next: (result) => {
              this.models = this.models.filter((val) => val.id !== model.id);
              this.load(this.selectedState.id);
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });

  }

  public applyTableFilterGlobal(table: Table, $event: any, filterType: any) {
    var tableFilterInput = $event.target as HTMLInputElement;
    table.filterGlobal(tableFilterInput.value, filterType);
  }

  clearFilters(table: Table, gFilter: HTMLInputElement) {
    table.clear();
    gFilter.value = '';
  }

  filterCountry(query: string) {
    this.filteredCountries = this.countries.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  filterState(query: string) {
    this.filteredStates = this.states?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  onCountrySelect(selected: CountryModel) {
    this.selectedCountry = selected;
    this.loadStates(this.selectedCountry.id);
  }

  onStateSelected(selected: StateModel) {
    this.selectedState = selected;
    this.load(this.selectedState.id);
  }
}
