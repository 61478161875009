import { Injectable } from '@angular/core';
import { CompanyModel } from '../../models/medicius/comercial/company-model';
import { BaseOperationService } from '../infra/base-operation.service';
import { Observable, switchMap, of, ReplaySubject } from 'rxjs';
import { OperationResults } from '../../models/response/operation-results';
import { CompanySectorModel } from '../../models/medicius/comercial/company-sector-model';
import { HttpClientService } from '../infra/http-client.service';
import { dE } from '@fullcalendar/core/internal-common';
import { CompanyLocationModel } from '../../models/medicius/comercial/company-location-model';

@Injectable({
  providedIn: 'root'
})
export class UserCompanySectorsService extends HttpClientService {
  protected override getUrlPath(): string {
    return "UserCompanySectors";
  }

  protected createInstance(instance: CompanyModel): CompanyModel {

    return new CompanyModel(instance);
  }


  myCurrentCompany: CompanyModel;
  myCurrentCompanySector: CompanySectorModel;
  myCurrentCompanyLocation: CompanyLocationModel;

  public getMyCurrentCompanySector(): CompanySectorModel {
    if (!this.myCurrentCompanySector && localStorage.getItem('_myCurrentCompanySector')) {
      this.setMyCurrentCompanySector(new CompanySectorModel(JSON.parse(localStorage.getItem('_myCurrentCompanySector'))));
    }

    return this.myCurrentCompanySector;
  }

  public setMyCurrentCompanySector(v: CompanySectorModel | number) {
    if (v) {
      const temp: CompanySectorModel = typeof v === "number" ? this.myAssociatedCompaniesSectors.find(x => x.id === v) : v;

      localStorage.setItem('_myCurrentCompanySector', JSON.stringify(temp));
      this.myCurrentCompanySector = temp;
      this.myCurrentCompany = this.myAssociatedCompanies.find(x => x.id === temp.companyId);
      this.myCurrentCompanySubject.next(this.myCurrentCompany);
    } else {
      localStorage.removeItem('_myCurrentCompanySector');
      this.myCurrentCompanySector = null;
      this.myCurrentCompanySubject.next(null);
    }
  }



  public getMyCurrentCompanyLocation(): CompanyLocationModel {
    if (!this.myCurrentCompanyLocation && localStorage.getItem('_myCurrentCompanyLocation')) {
      this.setMyCurrentCompanyLocation(new CompanyLocationModel(JSON.parse(localStorage.getItem('_myCurrentCompanyLocation'))));
    }

    return this.myCurrentCompanyLocation;
  }

  public setMyCurrentCompanyLocation(v: CompanyLocationModel | number) {
    if (v) {
      const temp: CompanyLocationModel = typeof v === "number" ? this.myAssociatedCompaniesLocations.find(x => x.id === v) : v;

      localStorage.setItem('_myCurrentCompanyLocation', JSON.stringify(temp));
      this.myCurrentCompanyLocation = temp;
      this.myCurrentCompany = this.myAssociatedCompanies.find(x => x.id === temp.companyId);
      this.myCurrentCompanySubject.next(this.myCurrentCompany);
    } else {
      localStorage.removeItem('_myCurrentCompanyLocation');
      this.myCurrentCompanyLocation = null;
      this.myCurrentCompanySubject.next(null);
    }
  }



  private myAssociatedCompanies: CompanyModel[];
  private myAssociatedCompaniesSectors: CompanySectorModel[];
  private myAssociatedCompaniesLocations: CompanyLocationModel[];
  private myCompaniesSubject = new ReplaySubject<CompanyModel[] | null>(1);
  private myCurrentCompanySubject = new ReplaySubject<CompanyModel | null>(1);
  myCompanies$ = this.myCompaniesSubject.asObservable();
  myCurrentCompany$ = this.myCurrentCompanySubject.asObservable();



  public getMyCompanies(): Observable<OperationResults<CompanyModel>> {
    return this.executeGet(`${this.getUrlPath()}/mycompanysectors`).pipe(switchMap((result: OperationResults<CompanyModel>) => {
      result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
      this.myAssociatedCompanies = result.items;

      if (!this.myAssociatedCompanies?.length || !this.myAssociatedCompanies.flatMap(x => x.companySectors)?.length) {
        this.setMyCurrentCompanySector(null);

      }

      if (!this.myAssociatedCompanies?.length || !this.myAssociatedCompanies.flatMap(x => x.companyLocations)?.length) {
        this.setMyCurrentCompanyLocation(null);
      }

      this.myAssociatedCompaniesSectors = this.myAssociatedCompanies.map(x => x.companySectors.map(s => {
        const cs = new CompanySectorModel(s);
        cs.company = new CompanyModel(x);
        cs.company.companySectors = [];
        return cs;
      })).flat();

      this.myAssociatedCompaniesLocations = this.myAssociatedCompanies.map(x => x.companyLocations.map(s => {
        const cs = new CompanyLocationModel(s);
        cs.company = new CompanyModel(x);
        cs.company.companyLocations = [];
        return cs;
      })).flat();

      this.myCompaniesSubject.next(result.items);
      return of(result);
    }));
  }



}
