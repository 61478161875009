import { BaseModelInt } from "../../infra/base-model";
import { NamedBaseModelInt } from "../../infra/named-model";
import { CompanyModel } from "../comercial/company-model";
import { PatientPersonModel } from "../comercial/patient-person-model";


export class RiskCategoryModel extends NamedBaseModelInt {

    constructor(other?: RiskCategoryModel | undefined) {
        super(other);

    }

}
