export enum StringComparisonType {
    StartsWith,
    Contains,
    NotContains,
    Equals,
    NotEquals,
    EndsWith
}

export enum NumberComparisonType {
    Equals,
    NotEquals,
    GT,
    GTE,
    LT,
    LTE
}

export enum DateComparisonType {
    Equals,
    NotEquals,
    GT,
    LT,
}