import { BaseModelInt } from "../infra/base-model";
import { NamedBaseModelInt } from "../infra/named-model";
import { CallIdModel } from "./call-id-model";
import { PriorityTypeModel } from "./priority-type-model";
import { ProductModel } from "./product-model";
import { ServiceOrderTypeModel } from "./service-records/service-order-type-model";

export class CallIdStatusModel extends NamedBaseModelInt {
    constructor(other?: CallIdStatusModel | undefined) {
        super(other);
        if (other) {
            this.callIds = other.callIds ? other.callIds.map(x => new CallIdModel(x)) : other.callIds;
        }
    }

    public callIds: CallIdModel[] = [];

    static KnownStatus = class {
        public static readonly OPEN = "Aberto";
        public static readonly CALLED = "Chamado";
        public static readonly AWAITING = "Aguardando";
        public static readonly INPROGRESS = "Em andamento";
        public static readonly CLOSED = "Fechado";
    }
}

