import { PhysicalLocationTypeModel } from '../../../shared/models/addressing/physical-location-type-model';
import { PhysicalLocationTypeService } from '../../../shared/services/addressing/physical-location-type.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { PhysicalLocationTypeItemComponent } from './physical-location-type-item/physical-location-type-item.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-physical-location-type',
  templateUrl: './physical-location-type.component.html',
  styleUrls: ['./physical-location-type.component.scss'],
})
export class PhysicalLocationTypeComponent implements OnInit {
  models!: PhysicalLocationTypeModel[];
  selectedItems!: PhysicalLocationTypeModel[] | null;
  constructor(
    private translateService: TranslateService,
    private service: PhysicalLocationTypeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    const subscription = this.service.getAll().subscribe({
      next: (result) => {
        this.models = result.items;
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  openRegistry(model: PhysicalLocationTypeModel) {
    const ref = this.dialogService.open(PhysicalLocationTypeItemComponent, {
      data: { model: model ? model : new PhysicalLocationTypeModel(), title: model ? "Edição" : "Cadastro" },
      width: '50%',
      contentStyle: { overflow: 'hidden' },
      appendTo: 'body',
      modal: true,
      showHeader: false,
      closeOnEscape: false,
      closable: false,
    });

    ref?.onClose.subscribe(() => {
      this.load();
    });
  }

  deleteSelectedItems() {
    this.translateService.get(['operations.delete.items', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details']).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.items'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        key: 'delete',

        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          const subscription = this.service.delete(this.selectedItems).subscribe({
            next: (result) => {
              //this.models = this.models.filter((val) => !this.selectedItems?.includes(val));
              this.load();
              this.selectedItems = null;
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });
  }

  editItem(model: PhysicalLocationTypeModel) {
    this.openRegistry(new PhysicalLocationTypeModel(model))
  }

  deleteItem(model: PhysicalLocationTypeModel) {

    this.translateService.get(['operations.delete.item', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details'], { name: model.name }).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.item'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        icon: 'pi pi-exclamation-triangle',
        key: "delete",
        accept: () => {
          const subscription = this.service.delete(model).subscribe({
            next: (result) => {
              this.models = this.models.filter((val) => val.id !== model.id);
              this.load();
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });




  }

  public applyTableFilterGlobal(table: Table, $event: any, filterType: any) {
    var tableFilterInput = $event.target as HTMLInputElement;
    table.filterGlobal(tableFilterInput.value, filterType);
  }

  clearFilters(table: Table) {
    table.clear();
  }
}
