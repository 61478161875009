import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { CityModel } from "../../../../shared/models/addressing/city-model";
import { CountryModel } from "../../../../shared/models/addressing/country-model";
import { PhysicalLocationModel } from "../../../../shared/models/addressing/physical-location-model";
import { StateModel } from "../../../../shared/models/addressing/state-model";
import { PhysicalLocationService } from "../../../../shared/services/addressing/physical-location.service";

import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { CityService } from "src/app/modules/shared/services/addressing/city.service";
import { NeighborhoodService } from "src/app/modules/shared/services/addressing/neighborhood.service";
import { StateService } from "src/app/modules/shared/services/addressing/state.service";
import { NeighborhoodModel } from '../../../../shared/models/addressing/neighborhood-model';
import { PhysicalLocationTypeModel } from "src/app/modules/shared/models/addressing/physical-location-type-model";
import { PhysicalLocationTypeService } from "src/app/modules/shared/services/addressing/physical-location-type.service";

@Component({
  selector: "app-physical-location-item",
  templateUrl: "./physical-location-item.component.html",
  styleUrls: ["./physical-location-item.component.scss"],
})
export class PhysicalLocationItemComponent implements OnInit {
  @Input() model: PhysicalLocationModel;
  @Output() savedITem = new EventEmitter<PhysicalLocationModel>();

  countries: CountryModel[] | undefined;
  selectedCountry: CountryModel;
  filteredCountries: CountryModel[] | undefined;

  states: StateModel[] | undefined;
  selectedState: StateModel;
  filteredStates: StateModel[] | undefined;

  cities: CityModel[] | undefined;
  selectedCity: CityModel;
  filteredCities: CityModel[] | undefined;

  neighborhoods: NeighborhoodModel[] | undefined;
  selectedNeighborhood: NeighborhoodModel;
  filteredNeighborhoods: NeighborhoodModel[] | undefined;

  physicallocationtypes: PhysicalLocationTypeModel[] | undefined;
  selectedPhysicalLocationType: PhysicalLocationTypeModel;
  filteredPhysicalLocationTypes: PhysicalLocationTypeModel[] | undefined;


  editMode: boolean = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private service: PhysicalLocationService,
    private stateService: StateService,
    private cityService: CityService,
    private neighborhoodService: NeighborhoodService,
    private physicalLocationTypeService: PhysicalLocationTypeService,
    private translateService: TranslateService,
    private messageService: MessageService

  ) {

    this.model = this.config.data.model;
    this.countries = this.config.data.countries;

    this.editMode = this.model?.neighborhood?.id > 0;
    if (this.editMode) {
      this.selectedNeighborhood = this.model.neighborhood;
      this.selectedCity = this.model.neighborhood.city;
      this.selectedState = this.model.neighborhood.city.state;
      this.selectedCountry = this.model.neighborhood.city.state.country;
      this.selectedPhysicalLocationType = this.model.physicalLocationType;
    }
    else {
      if (this.config.data?.selectedCountry?.id > 0) {
        this.onCountrySelect(this.config.data.selectedCountry);

      }

      if (this.config.data?.selectedCountry?.id > 0 && this.config.data?.selectedState?.id > 0) {
        this.onStateSelect(this.config.data.selectedState);
      }

      if (this.config.data?.selectedCountry?.id > 0 && this.config.data?.selectedState?.id > 0 && this.config.data?.selectedCity) {
        this.onCitySelect(this.config.data.selectedCity);
      }

      if (this.config.data?.selectedCountry?.id > 0 && this.config.data?.selectedState?.id > 0 && this.config.data?.selectedCity?.id > 0 && this.config.data?.selectedNeighborhood) {
        this.onNeighborhoodSelect(this.config.data.selectedNeighborhood);
      }
    }
  }

  save(form: NgForm) {

    if (form.valid) {
      this.translateService.get(['operations.save.summary', 'operations.save.details']).subscribe((messages) => {
        const summaryMessage = messages['operations.save.summary'];
        const detailsMessage = messages['operations.save.details'];
        // var stateCopy = this.model.state;
        // this.model.state = null;
        const subscription = this.service.save(this.model).subscribe({
          next: (result) => {

            this.savedITem.emit(result.item);
            this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
          },
          complete: () => { subscription.unsubscribe(); this.close(); }
        });
      });
    }
  }

  close() {
    this.ref?.close();
  }

  ngOnInit(): void {
    const subscription = this.physicalLocationTypeService.getAll().subscribe({
      next: (result) => {
        this.physicallocationtypes = result.items;
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  filterPhysicalLocationType(query: string) {
    this.filteredPhysicalLocationTypes = this.physicallocationtypes.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  onPhysicalLocationTypeSelect(selected: PhysicalLocationTypeModel) {
    this.selectedPhysicalLocationType = selected;
    this.model.physicalLocationType = selected;
  }


  filterCountry(query: string) {
    this.filteredCountries = this.countries.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  onCountrySelect(selected: CountryModel) {
    this.selectedCountry = selected;
    this.states = [];
    this.cities = [];
    this.neighborhoods = [];
    this.selectedState = null;
    this.selectedCity = null;
    this.selectedNeighborhood = null;
    this.loadStates(this.selectedCountry.id);
  }

  loadStates(countryId: number) {

    const subscription = this.stateService.getAllByCountryId(countryId).subscribe({
      next: (result) => {
        this.states = result.items;
        this.filterState("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  onStateSelect(selected: StateModel) {
    this.cities = [];
    this.selectedCity = null;
    this.neighborhoods = [];
    this.selectedNeighborhood = null;

    this.selectedState = selected;
    if (this.selectedState) {
      this.selectedState.country = this.selectedCountry;
      this.loadCities(this.selectedState.id)
    }
  }

  filterState(query: string) {
    this.filteredStates = this.states?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  loadCities(stateId: number) {
    const subscription = this.cityService.getAllByStateId(stateId).subscribe({
      next: (result) => {
        this.cities = result.items;
        this.filterCity("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  filterCity(query: string) {
    this.filteredCities = this.cities?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  onCitySelect(selected: CityModel) {
    this.neighborhoods = [];
    this.selectedNeighborhood = null;

    this.selectedCity = selected;
    if (this.selectedCity) {
      this.selectedCity.state = this.selectedState;
      this.loadNeighborhoods(this.selectedCity.id);
    }

  }


  loadNeighborhoods(cityId: number) {
    const subscription = this.neighborhoodService.getAllByCityId(cityId).subscribe({
      next: (result) => {
        this.neighborhoods = result.items;
        this.filterNeighborhood("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  filterNeighborhood(query: string) {
    this.filteredNeighborhoods = this.neighborhoods?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  onNeighborhoodSelect(selected: NeighborhoodModel) {
    this.selectedNeighborhood = selected;
    if (this.selectedNeighborhood) {

      this.selectedNeighborhood.city = this.selectedCity;
    }

    this.model.neighborhood = this.selectedNeighborhood;
  }


}
