import { Component, OnInit, TemplateRef } from '@angular/core';
import { CityModel } from '../../../shared/models/addressing/city-model';
import { CountryModel } from '../../../shared/models/addressing/country-model';
import { PhysicalLocationModel } from '../../../shared/models/addressing/physical-location-model';
import { StateModel } from '../../../shared/models/addressing/state-model';
import { PhysicalLocationService } from '../../../shared/services/addressing/physical-location.service';
import { NeighborhoodService } from '../../../shared/services/addressing/neighborhood.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { CountryService } from 'src/app/modules/shared/services/addressing/country.service';
import { CityService } from 'src/app/modules/shared/services/addressing/city.service';
import { StateService } from 'src/app/modules/shared/services/addressing/state.service';
import { NeighborhoodItemComponent } from '../neighborhood/neighborhood-item/neighborhood-item.component';
import { NeighborhoodModel } from 'src/app/modules/shared/models/addressing/neighborhood-model';
import { PhysicalLocationItemComponent } from './physical-location-item/physical-location-item.component';


@Component({
  selector: 'app-physical-location',
  templateUrl: './physical-location.component.html',
  styleUrls: ['./physical-location.component.scss'],
})
export class PhysicalLocationComponent implements OnInit {
  models: PhysicalLocationModel[] = [];
  selectedItems!: PhysicalLocationModel[] | null;

  countries: CountryModel[] | undefined;
  selectedCountry: CountryModel;
  filteredCountries: CountryModel[] | undefined;
  states: StateModel[] | undefined;
  selectedState: StateModel;
  filteredStates: StateModel[] | undefined;

  cities: CityModel[] | undefined;
  selectedCity: CityModel;
  filteredCities: CityModel[] | undefined;

  neighborhoods: NeighborhoodModel[] | undefined;
  selectedNeighborhood: NeighborhoodModel;
  filteredNeighborhoods: NeighborhoodModel[] | undefined;

  constructor(
    private translateService: TranslateService,
    private service: PhysicalLocationService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private neighborhoodService: NeighborhoodService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.loadCountries();

  }

  loadCountries() {
    this.countries = [];
    const subscription = this.countryService.getAll().subscribe({
      next: (result) => {
        this.countries = result.items;
        this.filterCountry("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  loadStates(countryId: number) {
    this.states = [];
    const subscription = this.stateService.getAllByCountryId(countryId).subscribe({
      next: (result) => {
        this.states = result.items;
        this.filterState("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  loadCities(stateId: number) {
    this.cities = [];
    const subscription = this.cityService.getAllByStateId(stateId).subscribe({
      next: (result) => {
        this.cities = result.items;
        this.filterCity("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  loadNeighborhoods(cityId: number) {
    this.neighborhoods = [];
    const subscription = this.neighborhoodService.getAllByCityId(cityId).subscribe({
      next: (result) => {
        this.neighborhoods = result.items;
        this.filterNeighborhood("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  load(neighborhoodId: number) {
    this.models = [];
    const subscription = this.service.getAllByNeighborhoodId(neighborhoodId).subscribe({
      next: (result) => {
        this.models = result.items;
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  openRegistry(model: PhysicalLocationModel) {

    let modelParam = model ? model : new PhysicalLocationModel();
    if (this.selectedNeighborhood && model) {
      modelParam.neighborhood = this.selectedNeighborhood;
    }

    const ref = this.dialogService.open(PhysicalLocationItemComponent, {
      data: { model: modelParam, countries: this.countries, selectedCountry: this.selectedCountry, selectedState: this.selectedState, selectedCity: this.selectedCity, selectedNeighborhood: this.selectedNeighborhood },
      width: '50%',
      contentStyle: { overflow: 'hidden' },
      appendTo: 'body',
      modal: true,
      showHeader: false,
      closeOnEscape: false,
      closable: false,
    });

    ref?.onClose.subscribe(() => {

      if (!this.selectedNeighborhood) {
        if (modelParam.neighborhood?.id > 0) {
          this.onCountrySelect(modelParam.neighborhood.city.state.country);
          this.onStateSelected(modelParam.neighborhood.city.state);
          this.onCitySelected(modelParam.neighborhood.city);
          this.onNeighborhoodSelected(modelParam.neighborhood);
        }
      } else {
        this.load(this.selectedNeighborhood.id);
      }
    });
  }

  deleteSelectedItems() {
    this.translateService.get(['operations.delete.items', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details']).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.items'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        icon: 'pi pi-exclamation-triangle',
        key: 'delete',
        accept: () => {
          const subscription = this.service.delete(this.selectedItems).subscribe({
            next: (result) => {
              //this.models = this.models.filter((val) => !this.selectedItems?.includes(val));
              this.load(this.selectedNeighborhood.id);
              this.selectedItems = null;
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });
  }

  editModel(model: PhysicalLocationModel) {
    this.openRegistry(new PhysicalLocationModel(model))
  }

  deleteModel(model: PhysicalLocationModel) {
    this.translateService.get(['operations.delete.item', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details'], { name: model.name }).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.item'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        icon: 'pi pi-exclamation-triangle',
        key: 'delete',
        accept: () => {
          const subscription = this.service.delete(model).subscribe({
            next: (result) => {
              this.models = this.models.filter((val) => val.id !== model.id);
              this.load(this.selectedNeighborhood.id);
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });

  }

  public applyTableFilterGlobal(table: Table, $event: any, filterType: any) {
    var tableFilterInput = $event.target as HTMLInputElement;
    table.filterGlobal(tableFilterInput.value, filterType);
  }

  clearFilters(table: Table, gFilter: HTMLInputElement) {
    table.clear();
    gFilter.value = '';
  }

  filterCountry(query: string) {
    this.filteredCountries = this.countries.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  filterState(query: string) {
    this.filteredStates = this.states?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  filterCity(query: string) {
    this.filteredCities = this.cities?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  filterNeighborhood(query: string) {
    this.filteredNeighborhoods = this.neighborhoods?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  onCountrySelect(selected: CountryModel) {
    this.selectedCountry = selected;
    this.selectedState = null;
    this.selectedCity = null;
    this.selectedNeighborhood = null;

    this.loadStates(this.selectedCountry.id);
  }

  onStateSelected(selected: StateModel) {
    this.selectedState = selected;
    this.selectedState.country = this.selectedCountry;
    this.selectedCity = null;
    this.selectedNeighborhood = null;
    this.loadCities(this.selectedState.id);

  }

  onCitySelected(selected: CityModel) {
    this.selectedCity = selected;
    this.selectedCity.state = this.selectedState;
    this.selectedNeighborhood = null;
    this.loadNeighborhoods(this.selectedCity.id);
  }

  onNeighborhoodSelected(selected: NeighborhoodModel) {
    this.selectedNeighborhood = selected;
    this.selectedNeighborhood.city = this.selectedCity;
    this.load(this.selectedNeighborhood.id);
  }

}
