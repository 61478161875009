import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {

    // constructor(private http: HttpClient, private resouceService: ResourceService) {
    //     resouceService.selectedLanguage.subscribe(res => {
    //         this.selectedLanguage = res;
    //     })
    // }
    // //For example 
    // getData() {
    //     return this.http.get(`Test/getData?lang=${this.selectedLanguage.isoCode2}`);
    // }

    test(): Promise<void> {

        return new Promise<void>((resolverCallback) => {
            alert('2131312');
            resolverCallback();
        });

    }
}