import { NamedBaseModelInt } from "../../infra/named-model";

export class RaceTypeModel extends NamedBaseModelInt {
    constructor(other?: RaceTypeModel | undefined) {
        super(other);
        if (other) {

        }

    }
}
