import { BaseModelInt } from "../../infra/base-model";
import { CompanySectorModel } from "../comercial/company-sector-model";
import { PatientPersonModel } from "../comercial/patient-person-model";
import { DbFileModel } from "../db-file-model";
import { ServiceOrderWorkflowStepModel } from "./service-order-workflow-step-model";

export class ServiceOrderWorkflowStepDbFile extends BaseModelInt {
    constructor(other?: ServiceOrderWorkflowStepDbFile | undefined) {
        super(other);
        if (other) {
            this.workflowStep = other.workflowStep ? new ServiceOrderWorkflowStepModel(other.workflowStep) : other.workflowStep;
            this.dbFile = other.dbFile ? new DbFileModel(other.dbFile) : other.dbFile;
            this.workflowStepId = other.workflowStepId;


            this.dbFileId = other.dbFileId;
        }
    }

    workflowStepId: number;
    workflowStep: ServiceOrderWorkflowStepModel;

    dbFileId: number;
    dbFile: DbFileModel;


}
