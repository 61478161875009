import { NamedBaseModelInt } from "../../infra/named-model";
import { ProductModel } from "../product-model";
import { CompanySectorModel } from "./company-sector-model";

export class CompanySectorProductModel extends NamedBaseModelInt {
    constructor(other?: CompanySectorProductModel | undefined) {
        super(other);
        if (other) {
            this.companySectorId = other.companySectorId;
            this.productId = other.productId;
            this.enabled = other.enabled;
            this.companySector = other.companySector ? new CompanySectorModel(other.companySector) : other.companySector;
            this.product = other.product ? new ProductModel(other.product) : other.product;
        }
    }

    public companySectorId: number;
    public companySector: CompanySectorModel;
    public productId: number;
    public product: ProductModel;
    public enabled: boolean = false;
}
