<div class="pt-2 grid" *ngIf="!!this.user">
    <div class="col-12 lg:col-4 xl:col-3">
        <input type="text" id="userEmail" pInputText class="w-full" [disabled]="true"
            value="{{!!user?.email ? user?.email :  (user?.firstName + user?.lastName)}}" />

    </div>
    <div class="col-12 lg:col-4 xl:col-3">
        <p-dropdown [options]="myCompanies" [(ngModel)]="selectedSector"
            (ngModelChange)="userCompanySectorsService.setMyCurrentCompanySector(selectedSector)" [selectOnFocus]="true"
            [group]="true" optionGroupLabel="name" optionGroupChildren="companySectors" optionLabel="name"
            optionValue="id" placeholder="Selecione o setor"
            *ngIf="myCompanies?.length && this.myCompanies[0].companySectors?.length" class=""
            [style]="{ 'width': '100%'}">
            <ng-template let-selectedItem pTemplate="selectedItem">
                <div class="flex align-items-center gap-2">
                    <div>{{ selectedItem.name }}</div>
                </div>
            </ng-template>
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span>{{ group.identifier + ' - ' + group.name }}</span>
                </div>
            </ng-template>
            <ng-template let-item pTemplate="Item">
                <div class="flex align-items-center gap-2">
                    <div>{{ item.name }}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <div class="col-12 lg:col-4 xl:col-3">
        <p-dropdown [options]="myCompanies" [(ngModel)]="selectedLocation"
            (ngModelChange)="userCompanySectorsService.setMyCurrentCompanyLocation(selectedLocation)" [group]="true"
            optionGroupLabel="name" optionGroupChildren="companyLocations" optionLabel="name" optionValue="id"
            placeholder="Selecione sua localização física"
            *ngIf="myCompanies?.length && this.myCompanies[0].companyLocations?.length" class=""
            [style]="{ 'width': '100%'}">
            <ng-template let-selectedItem pTemplate="selectedItem">
                <div class="flex align-items-center gap-2">
                    <div>{{ selectedItem.name }}</div>
                </div>
            </ng-template>
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span>{{ group.name }}</span>
                </div>
            </ng-template>
            <ng-template let-item pTemplate="Item">
                <div class="flex align-items-center gap-2">
                    <div>{{ item.name }}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
</div>