import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpClientService } from "../infra/http-client.service";
import { BaseOperationService } from "../infra/base-operation.service";
import { NeighborhoodModel } from "../../models/addressing/neighborhood-model";
import { Observable } from "rxjs";
import { OperationResults } from "../../models/response/operation-results";

@Injectable({
  providedIn: "root",
})
export class NeighborhoodService extends BaseOperationService<NeighborhoodModel> {
  protected override getUrlPath(): string {
    return "neighborhood";
  }

  protected override createInstance(instance: NeighborhoodModel): NeighborhoodModel {
    return new NeighborhoodModel(instance);
  }

  public getAllByCityId(
    cityId: number
  ): Observable<OperationResults<NeighborhoodModel>> {
    return this.executeGet(`${this.getUrlPath()}/all/bycity/${cityId}`);
  }
}
