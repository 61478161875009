export const environment = {
  production: true,
  API_URL: "[[API_URL]]",
  userLocalStorageKey: "userIdentity",
  GoogleClientApp: {
    ClientId:
      "[[GOOGLE_CLIENT_ID]]",
    ClientSecret: "[[GOOGLE_CLIENT_SECRET]]",
    scopes: ["openid", "email", "profile"],
    prompt: "select_account",
  },
  MicrosoftClienApp: {
    clientId: "45ceb18a-b577-4178-ab66-032088ed4df9",
    authority:
      "https://login.microsoftonline.com/70e9718f-4995-41b6-ae62-722d3ee50ec7",
    redirect_uri: "https://egomedicius.juranida.com.br",
    logout_redirect_uri: "https://localhost:4200/logout",
    scopes: ["openid", "email", "profile"],
    prompt: "select_account",
  },
  paypal: {
    clientId: "[[PAYPAL_CLIENTID]]"
  },
  mercadopago: {
    publicKey: "[[MP_PUBLIC_KEY]]"
  }
};
