import { NamedBaseModelInt } from "../infra/named-model";
import { StateModel } from "./state-model";

export class PostalCodeModel extends NamedBaseModelInt {
    constructor(other?: PostalCodeModel) {
        super(other);
    }


}
