import { ICloneable } from "../metadata/interfaces/ICloneable";
import { BaseModelInt, BaseModelString } from "./base-model";

export class NamedBaseModelInt extends BaseModelInt implements ICloneable<NamedBaseModelInt> {
  constructor(other?: NamedBaseModelInt) {
    super(other);
    this.name = other && other.name ? other.name : "";
  }

  clone(): NamedBaseModelInt {
    return new NamedBaseModelInt(this);
  }

  public name: string;


}

export class NamedBaseModelString extends BaseModelString {
  constructor(other?: NamedBaseModelString) {
    super(other);
    this.name = other && other.name ? other.name : "";
  }

  public name: string;
}
