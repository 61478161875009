import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/identity/authentication.service';

import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailConfirmationModel } from '../../../shared/models/request/identity/email-confirmation-model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { EmailModel } from '../../../shared/models/request/identity/email-model';


@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.scss']
})
export class EmailConfirmComponent implements OnInit {
  submitted: boolean = false;
  confirmed: boolean = false;
  model = new EmailModel()

  constructor(protected service: AuthenticationService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  requestNewEmailConfirmationCode(form: NgForm) {
    if (form.valid) {
      this.translateService.get(['operations.mailConfirmation.success.sent.header', 'operations.mailConfirmation.success.sent.body']).subscribe((messages) => {
        const header = messages['operations.mailConfirmation.success.sent.header'];
        const body = messages['operations.mailConfirmation.success.sent.body'];

        const subscription = this.service.requestNewEmailConfirmationCode(this.model.email).subscribe({
          next: (result) => {
            this.confirmationService.confirm({
              message: body,
              header: header,
              key: "mailConfirm",
              icon: 'pi pi-exclamation-triangle',
              rejectVisible: false,
              accept: () => {
                this.router.navigate(['../login'], { relativeTo: this.activatedRoute });
              }
            });
          },
          complete: () => { subscription.unsubscribe(); this.submitted = false; }
        });

      });


    }
  }


  ngOnInit(): void {

    this.activatedRoute.queryParamMap.subscribe((params) => {
      const emailConfirmationModel = new EmailConfirmationModel();

      emailConfirmationModel.confirmationCode = params.get('confirmationCode');
      emailConfirmationModel.email = params.get('email');

      if (emailConfirmationModel.confirmationCode && emailConfirmationModel.email) {

        this.translateService.get(['operations.mailConfirmation.success.confirmed.header', 'operations.mailConfirmation.success.confirmed.body']).subscribe((messages) => {
          const header = messages['operations.mailConfirmation.success.confirmed.header'];
          const body = messages['operations.mailConfirmation.success.confirmed.body'];
          const subscription = this.service.confirmEmail(emailConfirmationModel).subscribe({
            next: (result) => {
              this.confirmationService.confirm({
                message: body,
                header: header,
                key: "mailConfirm",
                icon: 'pi pi-exclamation-triangle',
                rejectVisible: false,
                accept: () => {
                  this.router.navigate(['../login'], { relativeTo: this.activatedRoute });
                }
              });
            },
            complete: () => { subscription.unsubscribe(); this.submitted = false; }
          });

        });
      } else {
        //this.router.navigate(['../login'], { relativeTo: this.activatedRoute });
      }
    });
  }


}
