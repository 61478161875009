import { Injectable } from "@angular/core";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { DialogService } from "primeng/dynamicdialog";

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  private opened = false;

  constructor(private dialogService: DialogService) { }

  openDialog(error: any): void {
    if (!this.opened) {

      this.opened = true;
      const dialogRef = this.dialogService.open(ErrorDialogComponent, {
        data: error,
        width: '70%',
        header: "Ocorreu um erro na operação",
        //showHeader: false,
        appendTo: "body",
        //autoZIndex: true,
        baseZIndex: 10000,
        contentStyle: { overflow: 'auto' },
        closeOnEscape: true,
        closable: true,
      });

      dialogRef.onClose.subscribe(() => {
        this.opened = false;

      });
    }
  }


}
