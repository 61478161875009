import { BaseModelInt } from "../../infra/base-model";

export class DocGeneralRegistryModel extends BaseModelInt {
    constructor(other?: DocGeneralRegistryModel | undefined) {
        super(other);
        if (other) {
            this.number = other.number;
            this.uf = other.uf;
            this.complement = other.complement;

            this.issuer = other.issuer;

            this.issuedDate = other.issuedDate && typeof other.issuedDate === "string" ? new Date(other.issuedDate) : other.issuedDate;
        }
    }

    public complement: string = null;
    public uf: string = null;
    public number: string;
    public issuer: string = null;
    public issuedDate: Date = null;
}
