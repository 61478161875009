import { DebugEventListener, Directive, ElementRef, Input, input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { autocompleteValidator } from '../validators/autocomplete-validator';

@Directive({
  selector: '[appValidateAutoComplete]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ValidateAutoCompleteDirective,
    multi: true
  }]
})
export class ValidateAutoCompleteDirective {
  constructor(private autocomplete: ElementRef) {

  }
  @Input() validValues: Array<{ id: number, name: string }>;
  validate(control: AbstractControl): ValidationErrors | null {
    return autocompleteValidator(this.validValues)(control);
  }

}
