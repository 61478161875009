import { BaseModelInt } from "../../infra/base-model";
import { PersonModel } from "../personal/person-model";
import { PatientBastionPersonModel } from "./patient-bastion-person-model";
import { PatientPersonAlergyModel } from './patient-person-alergy-model';

export class PatientPersonModel extends BaseModelInt {
    constructor(other?: PatientPersonModel | undefined) {
        super(other);
        this.person = new PersonModel();
        if (other) {
            this.person = other.person ? new PersonModel(other.person) : other.person;
            this.patientBastionPersons = other.patientBastionPersons ? other.patientBastionPersons.map(x => new PatientBastionPersonModel(x)) : other.patientBastionPersons;
            this.patientAlergies = other.patientAlergies ? other.patientAlergies.map(x => new PatientPersonAlergyModel(x)) : other.patientAlergies;
        }
    }

    public person: PersonModel;
    public patientBastionPersons: PatientBastionPersonModel[] = [];
    public patientAlergies: PatientPersonAlergyModel[] = [];
}