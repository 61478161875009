import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { CountryModel } from "../../../../shared/models/addressing/country-model";
import { NeighborhoodModel } from "../../../../shared/models/addressing/neighborhood-model";
import { StateModel } from "../../../../shared/models/addressing/state-model";
import { NeighborhoodService } from "../../../../shared/services/addressing/neighborhood.service";
import { CityModel } from "../../../../shared/models/addressing/city-model";
import { CityService } from "src/app/modules/shared/services/addressing/city.service";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { StateService } from "src/app/modules/shared/services/addressing/state.service";

@Component({
  selector: "app-neighborhood-item",
  templateUrl: "./neighborhood-item.component.html",
  styleUrls: ["./neighborhood-item.component.scss"],
})
export class NeighborhoodItemComponent implements OnInit {
  @Input() model: NeighborhoodModel;
  @Output() savedITem = new EventEmitter<NeighborhoodModel>();

  countries: CountryModel[] | undefined;
  selectedCountry: CountryModel;
  filteredCountries: CountryModel[] | undefined;

  states: StateModel[] | undefined;
  selectedState: StateModel;
  filteredStates: StateModel[] | undefined;

  cities: CityModel[] | undefined;
  selectedCity: CityModel;
  filteredCities: CityModel[] | undefined;

  editMode: boolean = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private service: NeighborhoodService,
    private cityService: CityService,
    private stateService: StateService,
    private translateService: TranslateService,
    private messageService: MessageService

  ) {

    this.model = this.config.data.model;
    this.countries = this.config.data.countries;

    this.editMode = this.model?.city?.id > 0;
    if (this.editMode) {
      this.selectedCity = this.model.city;
      this.selectedCountry = this.model.city.state.country;
      this.selectedState = this.model.city.state;
      this.selectedCity = this.model.city;
    }
    else {
      if (this.config.data?.selectedCountry?.id > 0) {
        this.onCountrySelect(this.config.data.selectedCountry);

      }

      if (this.config.data?.selectedCountry?.id > 0 && this.config.data?.selectedState?.id > 0) {
        this.onStateSelect(this.config.data.selectedState);
      }

      if (this.config.data?.selectedCountry?.id > 0 && this.config.data?.selectedState?.id > 0 && this.config.data?.selectedCity) {
        this.onCitySelect(this.config.data.selectedCity);
      }
    }
  }

  save(form: NgForm) {

    if (form.valid) {
      this.translateService.get(['operations.save.summary', 'operations.save.details']).subscribe((messages) => {
        const summaryMessage = messages['operations.save.summary'];
        const detailsMessage = messages['operations.save.details'];
        // var stateCopy = this.model.state;
        // this.model.state = null;
        const subscription = this.service.save(this.model).subscribe({
          next: (result) => {

            this.savedITem.emit(result.item);
            this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
          },
          complete: () => { subscription.unsubscribe(); this.close(); }
        });
      });
    }
  }

  close() {
    this.ref?.close();
  }

  ngOnInit(): void {

  }

  filterCountry(query: string) {
    this.filteredCountries = this.countries.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }


  onCountrySelect(selected: CountryModel) {
    this.selectedCountry = selected;
    this.states = [];
    this.cities = [];
    this.selectedState = null;
    this.selectedCity = null;
    this.loadStates(this.selectedCountry.id);
  }

  loadStates(countryId: number) {

    const subscription = this.stateService.getAllByCountryId(countryId).subscribe({
      next: (result) => {
        this.states = result.items;
        this.filterState("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  onStateSelect(selected: StateModel) {
    this.cities = [];
    this.selectedCity = null;

    this.selectedState = selected;
    if (this.selectedState) {
      this.selectedState.country = this.selectedCountry;
      this.loadCities(this.selectedState.id)
    }
  }

  filterState(query: string) {
    this.filteredStates = this.states?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  loadCities(stateId: number) {
    const subscription = this.cityService.getAllByStateId(stateId).subscribe({
      next: (result) => {
        this.cities = result.items;
        this.filterCity("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  onCitySelect(selected: CityModel) {
    this.selectedCity = selected;
    if (this.selectedCity) {
      this.selectedCity.state = this.selectedState;
    }

    this.model.city = this.selectedCity;
  }

  filterCity(query: string) {
    this.filteredCities = this.cities?.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }
}
