import { NamedBaseModelInt } from "../infra/named-model";
import { ProductModel } from "./product-model";

export class ProductImageModel extends NamedBaseModelInt {
    constructor(other?: ProductImageModel | undefined) {
        super(other);
        if (other) {
            this.productId = other.productId;
            this.extension = other.extension;
            this.product = other.product ? new ProductModel(other.product) : other.product;
        }

    }

    public productId: number;
    public product: ProductModel;

    public extension: string;
}
