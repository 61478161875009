import { CityModel } from "../../addressing/city-model";
import { CountryModel } from "../../addressing/country-model";
import { BaseModelInt } from "../../infra/base-model";

export class PlaceOfBirthModel extends BaseModelInt {
    constructor(other?: PlaceOfBirthModel | undefined) {
        super(other);
        if (other) {
            this.cityId = other.cityId;
            this.passportNumber = other.passportNumber;
            this.passportCountryIssuerId = other.passportCountryIssuerId;
            this.passportIssueDate = other.passportIssueDate && typeof other.passportIssueDate === "string" ? new Date(other.passportIssueDate) : other.passportIssueDate;
            this.passportExpiryDate = other.passportExpiryDate && typeof other.passportExpiryDate === "string" ? new Date(other.passportExpiryDate) : other.passportExpiryDate;

            this.city = other.city ? new CityModel(other.city) : other.city;
            this.passportCountryIssuer = other.passportCountryIssuer ? new CountryModel(other.passportCountryIssuer) : other.passportCountryIssuer;
        }
    }


    public cityId: number = null;
    public city: CityModel = null;



    public passportNumber: string;

    public passportCountryIssuerId: number;


    public passportCountryIssuer: CountryModel;

    public passportIssueDate: Date;

    public passportExpiryDate: Date;
}
