import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpClientService } from "../infra/http-client.service";
import { PhysicalLocationModel } from "../../models/addressing/physical-location-model";
import { BaseOperationService } from "../infra/base-operation.service";
import { Observable } from "rxjs";
import { OperationResults } from "../../models/response/operation-results";
import { OperationResult } from "../../models/response/operation-result";

@Injectable({
  providedIn: "root",
})
export class PhysicalLocationService extends BaseOperationService<PhysicalLocationModel> {
  protected override createInstance(instance: PhysicalLocationModel): PhysicalLocationModel {
    return new PhysicalLocationModel(instance);
  }

  protected override getUrlPath(): string {
    return "physicallocation";
  }

  public getAllByNeighborhoodId(
    cityId: number
  ): Observable<OperationResults<PhysicalLocationModel>> {
    return this.executeGet(`${this.getUrlPath()}/all/byneighborhood/${cityId}`);
  }


  public getCompleteLocation(
    physicalLocationId: number
  ): Observable<OperationResult<PhysicalLocationModel>> {
    return this.executeGet(`${this.getUrlPath()}/all/${physicalLocationId}`);
  }
}
