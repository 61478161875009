import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { IdentityRoutingModule } from './identity-routing.module';
import { RequestPasswordComponent } from './components/request-password/request-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LogoutComponent } from './components/logout/logout.component';
import { EmailConfirmComponent } from './components/email-confirm/email-confirm.component';
import { RegisterWithThirdPartyComponent } from './components/register-with-third-party/register-with-third-party.component';
import { RouterModule } from '@angular/router';
import { QRCodeViewerComponent } from './components/qrcode-viewer/qrcode-viewer.component';
import { QRCodeModule } from 'angularx-qrcode';
import { GAuthenticatorCodeValidatorComponent } from './components/gauthenticator-code-validator/gauthenticator-code-validator.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent,
    EmailConfirmComponent,
    RegisterWithThirdPartyComponent,
    QRCodeViewerComponent,
    GAuthenticatorCodeValidatorComponent],

  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    SharedModule,
    IdentityRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
  ],
  exports: [
    QRCodeViewerComponent,
    GAuthenticatorCodeValidatorComponent
  ]

})
export class IdentityModule { }
