import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from './modules/layout/app.layout.component';

@NgModule({
    imports: [
        RouterModule.forRoot([


            { path: 'identity', loadChildren: () => import('./modules/identity/identity.module').then(m => m.IdentityModule) },
            { path: 'auth', loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
            { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },

            // { path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },


            {
                path: '', component: AppLayoutComponent,
                children: [
                    { path: 'public', loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule) },
                    //{ path: '', loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                    { path: 'metadata', loadChildren: () => import('./modules/metadata/metadata.module').then(m => m.MetadataModule) },
                    { path: 'addressing', loadChildren: () => import('./modules/addressing/addressing.module').then(m => m.AddressingModule) },
                    { path: 'access', loadChildren: () => import('./modules/access-administration/access-administration.module').then(m => m.AccessAdministrationModule) },
                    { path: 'medicius', loadChildren: () => import('./modules/medicius/medicius.module').then(m => m.MediciusModule) },
                    { path: '', redirectTo: 'public', pathMatch: 'full' }
                    // { path: 'uikit', loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
                    // { path: 'utilities', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
                    // { path: 'documentation', loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
                    // { path: 'blocks', loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
                    // { path: 'pages', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) }
                ]
            },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload', useHash: false /*, enableTracing: true */ })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
