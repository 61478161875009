import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
  NgForm,
} from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { StateService } from "../../../../shared/services/addressing/state.service";
import { StateModel } from "../../../../shared/models/addressing/state-model";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { CountryModel } from "src/app/modules/shared/models/addressing/country-model";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";



@Component({
  selector: "app-state-item",
  templateUrl: "./state-item.component.html",
  styleUrls: ["./state-item.component.scss"],
})
export class StateItemComponent implements OnInit {
  @Input() model: StateModel;
  @Output() savedITem = new EventEmitter<StateModel>();

  countries: CountryModel[] | undefined;
  selectedCountry: CountryModel;
  filteredCountries: CountryModel[] | undefined;
  editMode: boolean = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private service: StateService,
    private translateService: TranslateService,
    private messageService: MessageService

  ) {
    this.model = this.config.data.model;
    this.countries = this.config.data.countries;
    this.editMode = this.model?.country?.id > 0;
    if (this.model?.country?.id > 0) {
      this.selectedCountry = this.model.country;
    } else if (this.config.data?.selectedCountry?.id > 0) {
      this.model.country = this.config.data.selectedCountry;
      this.selectedCountry = this.model.country;
    }
  }

  save(form: NgForm) {

    if (form.valid) {
      this.translateService.get(['operations.save.summary', 'operations.save.details']).subscribe((messages) => {
        const summaryMessage = messages['operations.save.summary'];
        const detailsMessage = messages['operations.save.details'];

        const subscription = this.service.save(this.model).subscribe({
          next: (result) => {

            this.savedITem.emit(result.item);
            this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
          },
          complete: () => { subscription.unsubscribe(); this.close(); }
        });
      });
    }
  }

  close() {
    this.ref?.close();
  }

  ngOnInit(): void {

  }

  filterCountry(query: string) {
    this.filteredCountries = this.countries.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }


  onCountrySelect(selected: CountryModel) {
    this.selectedCountry = selected;
    this.model.country = selected;

  }
}
