import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function autocompleteValidator(validValues: Array<{ id: number, name: string }>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }

        if (value && (!value.id || !value.name) && typeof value !== "number") {

            return { invalidSelection: true };
        }

        const foundItem = validValues?.find(x => (value !== "number" && x.name.includes(value.name) && x.id === value.id) || (typeof value === "number" && x.id === value));
        if (!foundItem && validValues?.length) {

            return { invalidSelection: true }
        }

        return null;
    }
}