<div class="container">
    <div class="row">
        <div class="col-12 align-self-center" *ngIf="error.status">
            <h5><b>Status Code: {{ error.status }}</b></h5>
        </div>

        <p class="col-12 align-self-center" *ngFor="let message of error?.error?.errors; let i = index">
            <p-message severity="danger" [text]="message"></p-message>
        </p>
    </div>
</div>