import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { PhysicalLocationTypeModel } from "../../../../shared/models/addressing/physical-location-type-model";
import { PhysicalLocationTypeService } from "../../../../shared/services/addressing/physical-location-type.service";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { CountryService } from "src/app/demo/service/country.service";
import { CountryModel } from "src/app/modules/shared/models/addressing/country-model";

@Component({
  selector: "app-physical-location-type-item",
  templateUrl: "./physical-location-type-item.component.html",
  styleUrls: ["./physical-location-type-item.component.scss"],
})
export class PhysicalLocationTypeItemComponent implements OnInit {
  @Input() model: PhysicalLocationTypeModel;
  @Output() savedITem = new EventEmitter<PhysicalLocationTypeModel>();
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private service: PhysicalLocationTypeService,
    private translateService: TranslateService,
    private messageService: MessageService

  ) {
    this.model = this.config.data.model;
  }

  save(form: NgForm) {

    if (form.valid) {
      this.translateService.get(['operations.save.summary', 'operations.save.details']).subscribe((messages) => {
        const summaryMessage = messages['operations.save.summary'];
        const detailsMessage = messages['operations.save.details'];

        const subscription = this.service.save(this.model).subscribe({
          next: (result) => {

            this.savedITem.emit(result.item);
            this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
          },
          complete: () => { subscription.unsubscribe(); this.close(); }
        });
      });
    }
  }

  close() {
    this.ref?.close();
  }


  ngOnInit(): void {

  }


}
