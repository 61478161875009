import { NamedBaseModelInt } from "../infra/named-model";
import { CityModel } from "./city-model";
import { CountryModel } from "./country-model";

export class StateModel extends NamedBaseModelInt {
  constructor(other?: StateModel) {
    super(other);
    if (other) {
      this.country = other.country ? new CountryModel(other.country) : new CountryModel();
      this.cities = other.cities ? other.cities.map(x => new CityModel(x)) : [];

      this.countryId = other.countryId;
      this.fCode = other.fCode;
      this.fsName = other.fsName;
    }
  }

  public fCode: string = "";
  public countryId: number = 0;
  //public country: CountryModel;

  private _country: CountryModel = new CountryModel();
  public get country(): CountryModel {
    return this._country;
  }
  public set country(v: CountryModel) {
    if (v) this.countryId = v.id;
    this._country = v;
  }

  public fsName: string = "";

  public cities: Array<CityModel> = new Array<CityModel>();
}
