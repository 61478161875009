import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { RequestPasswordComponent } from "./components/request-password/request-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { EmailConfirmComponent } from "./components/email-confirm/email-confirm.component";
import { RegisterWithThirdPartyComponent } from "./components/register-with-third-party/register-with-third-party.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent, },
  { path: 'register', component: RegisterComponent, pathMatch: 'full' },
  { path: 'logout', component: LogoutComponent, },
  { path: 'request-password', component: RequestPasswordComponent, },
  { path: 'reset-password', component: ResetPasswordComponent, },
  { path: 'email-confirm', component: EmailConfirmComponent, },
  { path: 'register/:provider', component: RegisterWithThirdPartyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IdentityRoutingModule { }
