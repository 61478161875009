import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientService } from '../infra/http-client.service';
import { BaseOperationService } from '../infra/base-operation.service';
import { CountryModel } from '../../models/addressing/country-model';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseOperationService<CountryModel> {
  protected override createInstance(instance: CountryModel): CountryModel {
    return new CountryModel(instance);
  }

  protected override getUrlPath(): string {
    return "country";
  }
}
