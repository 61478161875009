import { NamedBaseModelInt } from "../infra/named-model";
import { CallIdModel } from "./call-id-model";
import { ProductModel } from "./product-model";

export class PriorityTypeModel extends NamedBaseModelInt {
    constructor(other?: PriorityTypeModel | undefined) {
        super(other);
        if (other) {
            this.abbreviation = other.abbreviation;
            this.order = other.order;
            this.callIds = other.callIds ? other.callIds.map(x => new CallIdModel(x)) : other.callIds;
        }
    }

    public abbreviation: string;
    public order: number;
    public callIds: CallIdModel[] = [];
}

