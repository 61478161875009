import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { RegisterExternalModel } from 'src/app/modules/shared/models/request/identity/register-external-model';
import { UserAuthModel } from 'src/app/modules/shared/models/response/user-auth-model';
import { AuthenticationService } from 'src/app/modules/shared/services/identity/authentication.service';

@Component({
  selector: 'app-register-with-third-party',
  templateUrl: './register-with-third-party.component.html',
  styleUrl: './register-with-third-party.component.scss'
})
export class RegisterWithThirdPartyComponent implements OnInit {

  provider: string | null = null;
  access_token: string | null = null;
  userId: string | null = null;
  submitted: boolean = false;
  model: RegisterExternalModel = new RegisterExternalModel();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {

    this.activatedRoute.queryParamMap.subscribe({
      next: (params: any) => {
        this.provider = this.activatedRoute.snapshot.paramMap.get('provider');
        this.access_token = params.get('access_token');
        this.userId = params.get('userId');
        const email = params.get('email');

        if (this.provider && this.access_token && this.userId &&
          (this.provider === 'facebook' || this.provider === 'google')) {
          this.model.provider = this.provider;
          this.model.accessToken = this.access_token;
          this.model.userId = this.userId;
          this.model.email = email;
        } else {
          this.router.navigateByUrl('../../register');
        }
      }
    });


    this.authenticationService.user$.subscribe({
      next: (user: UserAuthModel | null) => {
        if (user) {
          this.router.navigateByUrl('/');
        } else {

        }
      }
    })
  }


  register(form: NgForm) {
    this.submitted = true;

    if (form.valid) {
      if (this.userId && this.access_token && this.provider) {
        const subscription = this.authenticationService.registerExternal(this.model).subscribe({
          next: (user) => {
            this.router.navigate(['../../login'], { relativeTo: this.activatedRoute });
            // this.notificationService.showModal("Registro bem sucedido", `O usuário ${(user.email || user.firstName)} foi criado com sucesso!`,
            //   () => {
            //     this.router.navigate(['../../login'], { relativeTo: this.activatedRoute });
            //   }
            // );
          },
          complete: () => { subscription.unsubscribe(); this.submitted = false; }
        });
      }
    }
  }

}
