import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";



@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent implements OnInit, OnDestroy {


  private _error: any;
  public get error(): any {
    return this._error;
  }
  @Input() public set error(v: any) {
    this._error = v;
  }


  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.error = config.data;
    console.log("constructor ErrorDialogComponent: error data", this.error);
  }

  dismiss() {
    this.ref.close();
  }

  ngOnInit(): void {
    console.log("ngOnInit ErrorDialogComponent: error data", this.error);
  }

  ngOnDestroy(): void {
    console.log("ngOnDestroy ErrorDialogComponent");
  }
}
