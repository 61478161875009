import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem, SelectItem, SelectItemGroup } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { Menu } from 'primeng/menu';
import { AuthenticationService } from '../shared/services/identity/authentication.service';
import { UserCompanySectorsService } from '../shared/services/business/user-company-sectors.service';
import { CompanyModel } from '../shared/models/medicius/comercial/company-model';
import { CompanySectorModel } from '../shared/models/medicius/comercial/company-sector-model';
import { map, filter } from 'rxjs';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    items: MenuItem[] = new Array<MenuItem>();
    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;



    constructor(public layoutService: LayoutService,
        public authenticationService: AuthenticationService,
        public userCompanySectorsService: UserCompanySectorsService,
    ) {
        authenticationService.user$.subscribe({
            next: (user) => {
                if (user) {

                }
            }
        });
    }



    ngOnInit(): void {
        this.items.push(
            {
                label: "Sair",
                icon: 'pi-sign-out',
                command: () => {
                    this.authenticationService.logout();
                }
            });
    }
}
