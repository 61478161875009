import { BaseModelInt } from "../../infra/base-model";
import { CallIdModel } from "../call-id-model";
import { CompanyModel } from "../comercial/company-model";
import { PatientPersonModel } from "../comercial/patient-person-model";
import { ServiceOrderStatusModel } from "./service-order-status-model";
import { ServiceOrderTypeModel } from "./service-order-type-model";
import { ServiceOrderWorkflowStepModel } from "./service-order-workflow-step-model";

export class ServiceOrderModel extends BaseModelInt {

    constructor(other?: ServiceOrderModel | undefined) {
        super(other);
        if (other) {
            this.patient = other.patient ? new PatientPersonModel(other.patient) : other.patient;
            this.company = other.company ? new CompanyModel(other.company) : other.company;
            this.serviceOrderStatus = other.serviceOrderStatus ? new ServiceOrderStatusModel(other.serviceOrderStatus) : other.serviceOrderStatus;
            this.serviceOrderType = other.serviceOrderType ? new ServiceOrderTypeModel(other.serviceOrderType) : other.serviceOrderType;
            this.steps = other.steps ? other.steps.map(x => new ServiceOrderWorkflowStepModel(x)) : other.steps;

            this.patientId = other.patientId;
            this.companyId = other.companyId;
            this.serviceOrderTypeId = other.serviceOrderTypeId;
            this.scheduledServiceOrderId = other.scheduledServiceOrderId;

            this.callIdId = other.callIdId;
            this.callId = other.callId ? new CallIdModel(other.callId) : other.callId;;
            this.description = other.description;
            this.serviceOrderStatusId = other.serviceOrderStatusId;
            //this.currentStepId = other.currentStepId;
            this.openDate = other.openDate && typeof other.openDate === "string" ? new Date(other.openDate) : other.openDate;
            this.closeDate = other.closeDate && typeof other.closeDate === "string" ? new Date(other.closeDate) : other.closeDate;
        }
    }
    patientId: number;
    patient: PatientPersonModel;
    companyId: number;
    company: CompanyModel;

    callIdId: number;
    callId: CallIdModel;
    description: string;
    openDate: Date;
    closeDate: Date;
    serviceOrderStatusId: number;
    serviceOrderStatus: ServiceOrderStatusModel;
    steps: ServiceOrderWorkflowStepModel[] = [];

    serviceOrderTypeId: number;
    serviceOrderType: ServiceOrderTypeModel;

    scheduledServiceOrderId: number;
}
