export class InstanceFactory {
    public static create<TType>(TInstance: { new(): TType; }): TType {
        return new TInstance();
    }

    public static createFrom<TType extends object>(obj: { new(): TType }, cloneData: any): TType {
        let referenced: TType = InstanceFactory.create(obj);
        Object.assign(referenced, cloneData)
        return referenced;
    }

    public static copy<T>(instance: T): T {
        if (instance == null) {
            return instance;
        }

        let newObject = {};
        Object.assign(newObject, instance);
        return newObject as T;
    }
}
