import { NamedBaseModelInt } from "../../infra/named-model";
import { PersonContactAddressModel } from "./person-contact-address-model";
import { PersonContactPhoneModel } from "./person-contact-phone-model";
import { DocGeneralRegistryModel } from "./doc-general-registry-model";
import { DocHealthCareModel } from "../professional/doc-health-care-model";
//import { DocPhysicalPersonModel } from "./doc-physical-person-model";
import { GenderTypeModel } from "./gender-type-model";
import { PlaceOfBirthModel } from "./place-of-birth-model";
import { RaceTypeModel } from "./race-type-model";
import { SocialGenderTypeModel } from "./social-gender-type";


export class PersonModel extends NamedBaseModelInt {
    constructor(other?: PersonModel | undefined) {
        super(other);
        if (other) {
            this.identifier = other.identifier;
            this.email = other.email;
            this.phone = other.phone;
            this.socialName = other.socialName;
            this.motherName = other.motherName;
            this.genderTypeId = other.genderTypeId;
            this.socialGenderTypeId = other.socialGenderTypeId;
            this.birthDate = other.birthDate && typeof other.birthDate === "string" ? new Date(other.birthDate) : other.birthDate;
            this.raceTypeId = other.raceTypeId;
            this.userId = other.userId;
            this.photoId = other.photoId;
            this.evidenceId = other.evidenceId;

            this.genderType = other.genderType ? new GenderTypeModel(other.genderType) : this.genderType;
            this.socialGenderType = other.socialGenderType ? new SocialGenderTypeModel(other.socialGenderType) : this.socialGenderType;
            this.raceType = other.raceType ? new RaceTypeModel(other.raceType) : this.raceType;
            this.contactAddresses = other.contactAddresses ? other.contactAddresses.map(x => new PersonContactAddressModel(x)) : other.contactAddresses;
            this.contactPhones = other.contactPhones ? other.contactPhones.map(x => new PersonContactPhoneModel(x)) : other.contactPhones;

            this.docGeneralRegistry = other.docGeneralRegistry ? new DocGeneralRegistryModel(other.docGeneralRegistry) : this.docGeneralRegistry;
            this.placeOfBirth = other.placeOfBirth ? new PlaceOfBirthModel(other.placeOfBirth) : this.placeOfBirth;
        }
    }

    identifier: string;
    email: string;
    phone: string;

    socialName: string | null;
    motherName: string;
    genderTypeId: number;
    genderType: GenderTypeModel | null;
    socialGenderTypeId: number;
    socialGenderType: SocialGenderTypeModel | null;
    birthDate: Date;
    raceTypeId: number;
    raceType: RaceTypeModel;
    contactAddresses: PersonContactAddressModel[] | null = [];
    contactPhones: PersonContactPhoneModel[] | null = [];
    docGeneralRegistry: DocGeneralRegistryModel | null;
    placeOfBirth: PlaceOfBirthModel | null;
    userId: number;




    photoId: number;
    evidenceId: number;

}
