<form (ngSubmit)="requestPasswordReset(form)" #form="ngForm" autocomplete="off">
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div>
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                    <div class="text-center mb-5">
                        <!-- <img src="assets/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3"> -->
                        <div class="text-900 text-3xl font-medium mb-3">{{ 'messages.signInWelcome' | translate}}</div>

                    </div>
                    <div>
                        <label [htmlFor]="email" class="block text-900 font-medium mb-2">{{'messages.email' |
                            translate}}</label>
                        <input type="text" #email="ngModel" pInputText name="email" [(ngModel)]="model.email"
                            minlength="8" maxlength="80" required [autofocus]="true" class="w-full md:w-30rem mb-1" />

                        <div *ngIf="submitted && !email.valid && (email.dirty || email.touched)">
                            <small class="p-error" *ngIf="email.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="email.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: email.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="email.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: email.errors?.['maxlength'].requiredLength } }}
                            </small>
                        </div>
                        <div></div>
                        <button pButton pRipple label="{{'messages.resend' |
                                translate}}" class="w-full p-3 md:w-30rem mt-3"></button>

                        <div class="flex align-items-center justify-content-between mt-3 gap-5">
                            <a class="text-link" routerLink="../login">{{'messages.backToLogin' |
                                translate}}</a>
                            <a class="text-link" routerLink="../register">{{'messages.registerYourself' |
                                translate}}</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-confirmDialog key="requestPassword"></p-confirmDialog>
</form>

<!-- <h1 id="title" class="title">Recuperação de senha</h1>
<p class="sub-title">Insira seu endereço de e-mail para enviarmos um link para resetar sua senha</p>

<form [formGroup]="currentForm" (ngSubmit)="requestPasswordReset()" autocomplete="off">

    <div class="form-control-group">
        <label class="label" for="email">Email:</label>
        <input nbInput fullWidth formControlName="email" #email name="email" id="email" autofocus [required]="true">
        <ng-container *ngIf="!currentForm.get('email')?.valid && (currentForm.get('email').touched || submitted)">
            <div class="caption status-danger" *ngIf="currentForm.get('email')?.hasError('required')">
                O e-mail é obrigatório!
            </div>
            <div class="caption status-danger" *ngIf="currentForm.get('email')?.hasError('minlength')">
                O tamanho mínimo é de 3 caracteres!
            </div>
            <div class="caption status-danger" *ngIf="currentForm.get('email')?.hasError('maxLength')">
                O tamanho máximo é de 40 caracteres!
            </div>
            <div class="caption status-danger" *ngIf="currentForm.get('email')?.hasError('pattern')">
                E-mail inválido!
            </div>
        </ng-container>
    </div>

    <button nbButton fullWidth status="primary" size="large" [disabled]="!currentForm.valid"
        [class.btn-pulse]="submitted">
        Solitar reset de senha
    </button>
</form>

<section class="sign-in-or-up" aria-label="Entre ou registre-se">
    <p><a class="text-link" routerLink="../login" skipLocationChange>Voltar para o login</a></p>
    <p><a routerLink="../register" class="text-link" skipLocationChange>Registre-se</a></p>
</section> -->