import { NamedBaseModelInt } from "../../infra/named-model";
import { ServiceOrderWorkflowStepModel } from "../service-records/service-order-workflow-step-model";
import { CompanyModel } from "./company-model";

export class CompanyLocationModel extends NamedBaseModelInt {
    constructor(other?: CompanyLocationModel | undefined) {
        super(other);
        if (other) {
            this.companyId = other.companyId;
            this.enabled = other.enabled;

            this.company = other.company ? new CompanyModel(other.company) : other.company;

        }

    }

    public companyId: number;
    public company: CompanyModel;
    public enabled: boolean = false;
}
