import { NamedBaseModelInt } from "../../../infra/named-model";
import { AlergyTypeModel } from "./alergy-type-model";

export class AlergyModel extends NamedBaseModelInt {
    constructor(other?: AlergyModel | undefined) {
        super(other);
        if (other) {
            this.alergyTypeId = other.alergyTypeId;

            this.alergyType = other.alergyType ? new AlergyTypeModel(other.alergyType) : other.alergyType;
        }
    }



    public alergyTypeId: number;
    public alergyType: AlergyTypeModel;
}
