import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-bad-request',
  templateUrl: './bad-request.component.html',
  styleUrls: ['./bad-request.component.scss']
})
export class BadRequestComponent implements OnInit, OnDestroy {

  private _error: any;
  public get error(): any {
    return this._error;
  }
  @Input() public set error(v: any) {
    this._error = v;
  }


  ngOnInit(): void {
    if (!this.error?.error?.errors && this.error.error && !Array.isArray(this.error.error)) {
      //must be fixed for moments where 404 has only 1 error
      this.error.error = { errors: [this.error.error] }
    } else if (Array.isArray(this.error.error)) {
      this.error.error = { errors: (this.error.error as Array<any>).map(x => x.description) }
    }

  }
  ngOnDestroy(): void {
    let test = this.error;

  }
}