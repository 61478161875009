import { Component, OnInit, TemplateRef } from '@angular/core';
import { StateModel } from '../../../shared/models/addressing/state-model';
import { StateService } from '../../../shared/services/addressing/state.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { StateItemComponent } from './state-item/state-item.component';
import { CountryModel } from '../../../shared/models/addressing/country-model';
import { CountryService } from '../../../shared/services/addressing/country.service';



@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss'],
})
export class StateComponent implements OnInit {
  models: StateModel[] = [];
  selectedItems!: StateModel[] | null;
  countries: CountryModel[] | undefined;
  selectedCountry: CountryModel;
  filteredCountries: CountryModel[] | undefined;
  constructor(
    private translateService: TranslateService,
    private service: StateService,
    private countryService: CountryService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.loadCountries();

  }

  loadCountries() {
    const subscription = this.countryService.getAll().subscribe({
      next: (result) => {
        this.countries = result.items;
        this.filterCountry("");
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  load(countryId: number) {

    const subscription = this.service.getAllByCountryId(countryId).subscribe({
      next: (result) => {
        this.models = result.items;
      },
      complete: () => { subscription.unsubscribe(); }
    });
  }

  openRegistry(model: StateModel) {

    let modelParam = model ? model : new StateModel();
    if (this.selectedCountry && model) {
      modelParam.country = this.selectedCountry;
    }
    const ref = this.dialogService.open(StateItemComponent, {
      data: { model: modelParam, countries: this.countries, selectedCountry: this.selectedCountry },
      width: '50%',
      contentStyle: { overflow: 'hidden' },
      appendTo: 'body',
      modal: true,
      showHeader: false,
      closeOnEscape: false,
      closable: false,
    });

    ref?.onClose.subscribe(() => {
      if (!this.selectedCountry) {
        if (modelParam.countryId > 0) {
          this.onCountrySelect(modelParam.country);
        }
      } else {
        this.load(this.selectedCountry.id);
      }
    });
  }

  deleteSelectedItems() {
    this.translateService.get(['operations.delete.items', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details']).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.items'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        icon: 'pi pi-exclamation-triangle',
        key: 'delete',
        accept: () => {
          const subscription = this.service.delete(this.selectedItems).subscribe({
            next: (result) => {
              //this.models = this.models.filter((val) => !this.selectedItems?.includes(val));
              this.load(this.selectedCountry.id);
              this.selectedItems = null;
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });
  }

  editModel(model: StateModel) {
    this.openRegistry(new StateModel(model))
  }

  deleteModel(model: StateModel) {
    this.translateService.get(['operations.delete.item', 'operations.delete.header', 'operations.delete.summary', 'operations.delete.details'], { name: model.name }).subscribe((messages) => {
      const deleteMessage = messages['operations.delete.item'];
      const headerMessage = messages['operations.delete.header'];
      const summaryMessage = messages['operations.delete.summary'];
      const detailsMessage = messages['operations.delete.details'];

      this.confirmationService.confirm({
        message: deleteMessage,
        header: headerMessage,
        icon: 'pi pi-exclamation-triangle',
        key: 'delete',
        accept: () => {
          const subscription = this.service.delete(model).subscribe({
            next: (result) => {
              this.models = this.models.filter((val) => val.id !== model.id);
              this.load(this.selectedCountry.id);
              this.messageService.add({ severity: 'success', summary: summaryMessage, detail: detailsMessage, life: 3000 });
            },
            complete: () => { subscription.unsubscribe(); }
          });
        }
      });
    });

  }

  public applyTableFilterGlobal(table: Table, $event: any, filterType: any) {
    var tableFilterInput = $event.target as HTMLInputElement;
    table.filterGlobal(tableFilterInput.value, filterType);
  }

  clearFilters(table: Table, gFilter: HTMLInputElement) {
    table.clear();
    gFilter.value = '';
  }

  filterCountry(query: string) {
    this.filteredCountries = this.countries.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
  }

  onCountrySelect(selected: CountryModel) {

    this.selectedCountry = selected;
    this.load(this.selectedCountry.id);

  }
}
