import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, switchMap } from 'rxjs';

@Pipe({
  name: 'dateTranslate',
  standalone: false
})
export class DateTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private datePipe: DatePipe) {
  }

  transform(value: string | Date, translateProperty: string): Observable<string> {
    if (!value || value === "") return of(value as string)
    const _value: string = typeof value !== 'string' ? (value as Date).toISOString() : value as string;
    if (!_value || _value === "") return of(_value)
    return this.translateService.get(translateProperty).pipe(switchMap((result) => {
      return of(this.datePipe.transform(_value, result));
    }));
  }
}


//sem uso por enquanto
@Pipe({
  name: 'dateTranslateComponentFormatPipe',
  standalone: false
})
export class DateTranslateComponentFormatPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private datePipe: DatePipe) {
  }

  transform(value: string): Observable<string> {
    return this.translateService.get(value);
  }
}
