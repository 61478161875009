<form (ngSubmit)="resetPassword(form)" #form="ngForm" autocomplete="off">
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div>
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                    <div class="text-center mb-5">
                        <!-- <img src="assets/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3"> -->
                        <div class="text-900 text-3xl font-medium mb-3">{{ 'messages.signInWelcome' | translate}}</div>

                    </div>
                    <div>
                        <label [htmlFor]="email" class="block text-900 font-medium mb-2">{{'messages.email' |
                            translate}}</label>
                        <input type="text" #email="ngModel" pInputText name="email" [(ngModel)]="model.email"
                            minlength="8" maxlength="80" required [autofocus]="true" class="w-full md:w-30rem mb-1"
                            [disabled]="true" />

                        <div *ngIf="submitted && !email.valid && (email.dirty || email.touched)">
                            <small class="p-error" *ngIf="email.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="email.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: email.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="email.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: email.errors?.['maxlength'].requiredLength } }}
                            </small>
                        </div>
                        <label [htmlFor]="userPassword"
                            class="block text-900 font-mediummt-3 mb-2">{{'messages.userPassword' |
                            translate}}</label>
                        <p-password #userPassword="ngModel" name="userPassword" placeholder="{{'messages.userPassword' |
                        translate}}" [toggleMask]="true" styleClass="mb-1" inputStyleClass="w-full p-3 md:w-30rem"
                            [(ngModel)]="model.newPassword" minlength="6" maxlength="30"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$"
                            autocomplete="off"></p-password>
                        <div *ngIf="submitted && !userPassword.valid && (userPassword.dirty || userPassword.touched)">
                            <small class="p-error"
                                *ngIf="userPassword.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="userPassword.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: userPassword.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="userPassword.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: userPassword.errors?.['maxlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="userPassword.errors?.['pattern']">
                                {{'validators.pattern.password' | translate }}
                            </small>
                        </div>

                        <label [htmlFor]="repeatPassword"
                            class="block text-900 font-mediummt-3 mb-2">{{'messages.repeatPassword' |
                            translate}}</label>
                        <p-password #repeatPassword="ngModel" name="repeatPassword" placeholder="{{'messages.repeatPassword' |
                                                translate}}" [toggleMask]="false" [feedback]="false" styleClass="mb-1"
                            inputStyleClass="w-full p-3 md:w-30rem" [(ngModel)]="model.repeatPassword" minlength="6"
                            maxlength="30"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$"
                            autocomplete="off"></p-password>
                        <div
                            *ngIf="submitted && !repeatPassword.valid && (repeatPassword.dirty || repeatPassword.touched)">
                            <small class="p-error" *ngIf="userPassword.value != repeatPassword.value">
                                {{'validators.pattern.password' | translate }}
                            </small>
                        </div>
                        <div></div>
                        <button pButton pRipple label="{{'messages.signIn' |
                                translate}}" class="w-full p-3 md:w-30rem mt-3"></button>

                        <div class="flex align-items-center justify-content-between mt-3 gap-5">
                            <a class="text-link" routerLink="../login">{{'messages.backToLogin' |
                                translate}}</a>
                            <a class="text-link" routerLink="../register">{{'messages.registerYourself' |
                                translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-confirmDialog key="resetPassword"></p-confirmDialog>
</form>

<!-- 
<h1 id="title" class="title">Reset de senha</h1>
<p class="sub-title">Por favor, defina uma nova senha</p>
<form (ngSubmit)="resetPass()" [formGroup]="currentForm" autocomplete="off">

    <div class="form-group">

        <label class="label" for="userPassword">Nova senha:</label>

        <input nbInput fullWidth formControlName="userPassword" #userPassword type="password" autocomplete="off">

        <ng-container
            *ngIf="!currentForm.get('userPassword')?.valid && (currentForm.get('userPassword').touched || submitted)">
            <div class="caption status-danger" *ngIf="currentForm.get('userPassword')?.hasError('required')">
                A senha é obrigatória!
            </div>
            <div class="caption status-danger" *ngIf="currentForm.get('userPassword')?.hasError('minlength')">
                O tamanho mínimo é de 6 caracteres!
            </div>
            <div class="caption status-danger" *ngIf="currentForm.get('userPassword')?.hasError('maxLength')">
                O tamanho máximo é de 30 caracteres!
            </div>
            <div class="caption status-danger" *ngIf="currentForm.get('userPassword')?.hasError('pattern')">
                A senha não possui os requisitos de segurança
            </div>
        </ng-container>
    </div>
    <div class="form-group">

        <label class="label" for="userPasswordRepeat">Confirme a nova Senha:</label>

        <input nbInput fullWidth formControlName="userPasswordRepeat" #userPasswordRepeat type="password"
            id="userPasswordRepeat">

        <ng-container
            *ngIf="!currentForm.get('userPasswordRepeat')?.valid && (currentForm.get('userPasswordRepeat').touched || submitted)">
            <div class="caption status-danger" *ngIf="currentForm.get('userPasswordRepeat')?.hasError('required')">
                A confirmação de senha é obrigatória!
            </div>

            <div class="caption status-danger" *ngIf="currentForm.get('userPasswordRepeat')?.hasError('pattern')">
                A senha não possui os requisitos de segurança
            </div>
         
        </ng-container>
    </div>
    <button nbButton status="primary" fullWidth size="large" [disabled]="submitted || !currentForm.valid"
        [class.btn-pulse]="submitted">
        Alterar senha
    </button>
</form>

<section class="sign-in-or-up" aria-label="Entre ou registre-se">
    <p><a class="text-link" routerLink="../login" skipLocationChange>Voltar para o login</a></p>
    <p><a routerLink="../register" class="text-link" skipLocationChange>Registre-se</a></p>
</section> -->