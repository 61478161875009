import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Router } from '@angular/router';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../services/identity/authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const url = this.router.routerState.snapshot.url;
    const loginUrl = this.authenticationService.getLoginUrl();
    //

    // if (!this.authenticationService.isAuthenticated() && !url.toLocaleLowerCase().includes(loginUrl.toLocaleLowerCase())) {
    //   this.router.navigateByUrl(this.authenticationService.getLoginUrl());
    // }
    return next.handle(
      this.bearedRequest(request, this.authenticationService.getUserAccessToken())
    );
  }

  private bearedRequest<T>(
    request: HttpRequest<T>,
    token: string
  ): HttpRequest<T> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
