import { Injectable } from "@angular/core";
import { Observable, Subject, catchError, debounceTime, map, of, switchMap, tap } from "rxjs";
import { BaseModelInt } from "../../models/infra/base-model";
import { HttpClientService } from "./http-client.service";
import { OperationResult } from "../../models/response/operation-result";
import { OperationResults } from "../../models/response/operation-results";
import { InstanceFactory } from "../../models/infra/instance-factory";
import { DateFilter, DynamicFilters, NumberFilter, StringFilter } from "../../models/request/filter-definition";
import { PagedOperationResults } from "../../models/response/paged-operation-results";
import {
  IServerFilteredTableLoader,
  IServerTableLoader,
} from "./baseInterfaces";
import { DateComparisonType, NumberComparisonType, StringComparisonType } from "../../models/request/filter-types";

@Injectable({
  providedIn: "root",
})
export abstract class BaseOperationService<T extends BaseModelInt>
  extends HttpClientService implements IServerFilteredTableLoader<T> {
  public insert(payload: Array<T>): Observable<OperationResults<T>>;
  public insert(payload: T): Observable<OperationResult<T>>;
  public insert(
    payload: Array<T> | T
  ): Observable<OperationResults<T>> | Observable<OperationResult<T>> {
    if (Array.isArray(payload)) {
      return <Observable<OperationResults<T>>>this.executePost(
        payload.map((x) => this.clone2Save(x)),
        `${this.getUrlPath()}/bulkinsert`
      );
    }
    return <Observable<OperationResult<T>>>(
      this.executePut(this.clone2Save(payload), this.getUrlPath())
    );
  }

  public update(payload: Array<T>): Observable<OperationResults<T>>;
  public update(payload: T): Observable<OperationResult<T>>;
  public update(
    payload: Array<T> | T
  ): Observable<OperationResults<T>> | Observable<OperationResult<T>> {
    if (Array.isArray(payload)) {
      return <Observable<OperationResults<T>>>this.executePost(
        payload.map((x) => this.clone2Save(x)),
        `${this.getUrlPath()}/bulkupdate`
      );
    }
    return <Observable<OperationResult<T>>>(
      this.executePost(this.clone2Save(payload), this.getUrlPath())
    );
  }

  public delete(payload: Array<T>): Observable<OperationResults<T>>;
  public delete(payload: T): Observable<OperationResult<T>>;
  public delete(
    payload: Array<T> | T
  ): Observable<OperationResults<T>> | Observable<OperationResult<T>> {
    if (Array.isArray(payload)) {
      const ids = payload.map((x) => x.id).join("&ids=");
      if (!ids?.length) return of(new OperationResults(payload.length, payload));
      return <Observable<OperationResults<T>>>(
        this.executeDelete(`${this.getUrlPath()}/delete?ids=${ids}`)
      );
    }
    if (!payload?.id) return of(new OperationResult(0, payload));
    return <Observable<OperationResult<T>>>(
      this.executeDelete(`${this.getUrlPath()}/${payload.id}`)
    );
  }

  public deleteWithBody(payload: Array<T>): Observable<OperationResults<T>>;
  public deleteWithBody(payload: T): Observable<OperationResult<T>>;
  public deleteWithBody(
    payload: Array<T> | T
  ): Observable<OperationResults<T>> | Observable<OperationResult<T>> {
    if (Array.isArray(payload)) {
      return <Observable<OperationResults<T>>>this.executeDeleteWithBody(
        payload.map((x) => this.clone2Save(x)),
        `${this.getUrlPath()}/deletebMulti`
      );
    }
    return <Observable<OperationResult<T>>>(
      this.executeDeleteWithBody(
        this.clone2Save(payload),
        `${this.getUrlPath()}/deleteb`
      )
    );
  }

  public getByIds(allIds: Array<number>): Observable<OperationResults<T>> {
    const ids = allIds.join("&ids=");
    return this.executeGet(`${this.getUrlPath()}/ids?ids=${ids}`).pipe(switchMap((result: OperationResults<T>) => {
      result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
      return of(result);
    }));
  }


  public get(payload: Array<T>): Observable<OperationResults<T>>;
  public get(payload: T): Observable<OperationResult<T>>;
  public get(payload: Number): Observable<OperationResult<T>>;
  public get(
    payload: Array<T> | T | Number
  ): Observable<OperationResults<T>> | Observable<OperationResult<T>> {
    if (Array.isArray(payload)) {
      const ids = payload.map((x) => x.id).join("&ids=");
      return this.executeGet(`${this.getUrlPath()}/${ids}`).pipe(switchMap((result: OperationResults<T>) => {
        result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
        return of(result);
      }));
    }

    if (Number.isInteger(payload)) {
      return this.executeGet(`${this.getUrlPath()}/${payload}`).pipe(switchMap((result: OperationResult<T>) => {
        result.item = result.item ? this.createInstance(result.item) : result.item;
        return of(result);
      }));
    }

    return this.executeGet(`${this.getUrlPath()}/${(payload as T).id}`).pipe(switchMap((result: OperationResult<T>) => {
      result.item = result.item ? this.createInstance(result.item) : result.item;
      return of(result);
    }));
  }

  public save(payload: Array<T>): Observable<OperationResults<T>>;
  public save(payload: T): Observable<OperationResult<T>>;
  public save(
    payload: Array<T> | T
  ): Observable<OperationResults<T>> | Observable<OperationResult<T>> {
    if (Array.isArray(payload)) {
      return this.executePost(
        payload.map((x) => this.clone2Save(x)),
        `${this.getUrlPath()}/bulksave`
      ).pipe(switchMap((result: OperationResults<T>) => {
        result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
        return of(result);
      }));
    }

    return this.executePost(this.clone2Save(payload), `${this.getUrlPath()}/save`).pipe(switchMap((result: OperationResult<T>) => {
      result.item = result.item ? this.createInstance(result.item) : result.item;
      return of(result);
    }));
  }

  public getAll(): Observable<OperationResults<T>> {
    return this.executeGet(`${this.getUrlPath()}/all`).pipe(switchMap((result: OperationResults<T>) => {
      result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
      return of(result);
    }));
  }



  public getPaginated(
    pageNumber: number,
    pageSize: number
  ): Observable<PagedOperationResults<T>> {
    return this.executeGet(
      `${this.getUrlPath()}/page?pageNumber=${pageNumber}&pageSize=${pageSize}`
    ).pipe(switchMap((result: PagedOperationResults<T>) => {
      result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
      return of(result);
    }));
  }


  public getModelFilteredPage(
    model: T,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedOperationResults<T>> {
    const dynamicFilters = this.toDynamicFilters(model);
    return this.getFilteredPage(dynamicFilters, pageNumber, pageSize);
  }



  public getFilteredPage(
    dynamicFilters: DynamicFilters,
    pageNumber: number,
    pageSize: number
  ): Observable<PagedOperationResults<T>> {
    return this.executePost(
      dynamicFilters,
      `${this.getUrlPath()}/pagefilter?pageNumber=${pageNumber}&pageSize=${pageSize}`
    ).pipe(switchMap((result: PagedOperationResults<T>) => {
      result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
      return of(result);
    }));
  }

  public clone2Save(payload: Array<T>): Array<T>;
  public clone2Save(payload: T): T;
  public clone2Save(payload: Array<T> | T): Array<T> | T {
    if (Array.isArray(payload)) {
      return payload.map((x) => this.clone2Save(x));
    }

    var newPayload = this.prepareCloned(payload, InstanceFactory.copy(payload));
    return newPayload;
  }

  public prepareCloned(original: T, cloned: T): T {
    return cloned;
  }

  protected abstract createInstance(instance: T): T;
  protected toDynamicFilters(instance: T): DynamicFilters {
    const dynamicFilters = new DynamicFilters();
    if (!instance) return dynamicFilters;
    var keys = Object.keys(instance);

    dynamicFilters.sFilters = keys.filter(x => typeof x === 'string').map(x => {
      const sFilter = new StringFilter();
      sFilter.comparisonType = StringComparisonType.Equals;
      sFilter.propName = x;
      sFilter.val = instance[x];
      return sFilter;
    });

    dynamicFilters.dFilters = keys.filter(x => this.isDateValid(instance[x as keyof T])).map(x => {
      const dFilter = new DateFilter();
      dFilter.comparisonType = DateComparisonType.Equals;
      dFilter.propName = x;
      dFilter.val = instance[x];
      return dFilter;
    });


    dynamicFilters.nFilters = keys.filter(x => this.isDateValid(instance[x as keyof T])).map(x => {
      const nFilter = new NumberFilter();
      nFilter.comparisonType = NumberComparisonType.Equals;
      nFilter.propName = x;
      nFilter.val = instance[x];
      return nFilter;
    });

    return dynamicFilters;
  }

  isDateValid(suposedDate: unknown): boolean {
    if (!suposedDate || suposedDate === '') return false;
    if (Object.prototype.toString.call(suposedDate) === "[object Date]") {
      return !Number.isNaN(new Date(suposedDate as string));
    }
    let x = new Date(suposedDate as string);

    if (x instanceof Date && !Number.isNaN(x)) {
      return true;
    }

    return false;
  }


  isNumber(suposedNumber: unknown): boolean {
    if (!Number.isNaN(suposedNumber)) return false;
    if (typeof suposedNumber === 'number') return true;
    return false;
  }


  private readonly loading = new Subject<boolean>();
  get loading$(): Observable<boolean> {
    return this.loading;
  }
  public search(information: string): Observable<OperationResults<T>> {
    return this.executeGet<OperationResults<T>>(
      `${this.getUrlPath()}/search?information=${information}`
    )
      .pipe(
        debounceTime(200),
        // Set loading to true when request begins
        tap(() => this.loading.next(true)),
        // Simulate server
        // If we get to this point, we know we got the data,
        // set loading to false, return only the items
        map((result) => {
          this.loading.next(false);
          result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
          return result;
        }),
        // Catch any errors
        catchError((err) => {
          console.log(err);
          this.loading.next(false);
          throw err;
        })
      );
  }



  public searchPaged(information: string, pageNumber: number, pageSize: number): Observable<PagedOperationResults<T>> {
    return this.executeGet<PagedOperationResults<T>>(
      `${this.getUrlPath()}/searchpaged?information=${information}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
      .pipe(
        debounceTime(200),
        // Set loading to true when request begins
        tap(() => this.loading.next(true)),
        // Simulate server
        // If we get to this point, we know we got the data,
        // set loading to false, return only the items
        map((result) => {
          result.items = result.items ? result.items.map(item => this.createInstance(item)) : result.items;
          this.loading.next(false);

          return result;
        }),
        // Catch any errors
        catchError((err) => {
          console.log(err);
          this.loading.next(false);
          throw err;
        })
      );
  }


}
