import { EmailModel } from "./email-model";

export class LoginExternalModel {

    constructor(other?: LoginExternalModel) {
        if (other) {
            this.userId = other.userId;
            this.accessToken = other.accessToken;
            this.provider = other.provider;
            this.email = other.email;
        }
    }

    userId: string;
    accessToken: string;
    provider: string;
    email: string;
}