<div class="container">
    <div class="row">


        <div class="col-12 align-self-center">
            <h1>Status Code: {{ error.status }}</h1>
        </div>
        <div class="col-12 align-self-center" *ngIf="error?.message">
            <p-message severity="danger" [text]="error.message"></p-message>
        </div>
        <div class="col-12 align-self-center" *ngIf="!error?.message">
            <p-message severity="danger" text="Erro inesperado!"></p-message>
        </div>
    </div>
</div>