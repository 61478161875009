import { NamedBaseModelInt } from "../infra/named-model";
import { StateModel } from "./state-model";

export class CountryModel extends NamedBaseModelInt {
    constructor(other?: CountryModel) {
        super(other);
        if (other) {
            this.states = other.states ? other.states.map(x => new StateModel(x)) : [];
        }
    }

    public states: Array<StateModel> = new Array<StateModel>();
}
