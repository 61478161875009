<form (ngSubmit)="register(form)" #form="ngForm" autocomplete="off">
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div>
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                    <div class="text-center mb-5">
                        <!-- <img src="assets/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3"> -->
                        <div class="text-900 text-3xl font-medium mb-3">{{ 'messages.registerWelcome' | translate}}
                        </div>

                    </div>
                    <div>
                        <label [htmlFor]="firstName" class="block text-900 font-medium mb-1">{{'messages.firstName' |
                            translate}}</label>
                        <input type="text" #firstName="ngModel" pInputText name="firstName"
                            [(ngModel)]="model.firstName" minlength="3" maxlength="40" required [autofocus]="true"
                            class="w-full md:w-30rem mb-1" />

                        <div *ngIf="submitted && !firstName.valid && (firstName.dirty || firstName.touched)">
                            <small class="p-error" *ngIf="firstName.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="firstName.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: firstName.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="firstName.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: firstName.errors?.['maxlength'].requiredLength } }}
                            </small>
                        </div>
                        <label [htmlFor]="lastName" class="block text-900 font-medium mb-2 mt-3">{{'messages.lastName' |
                            translate}}</label>
                        <input type="text" #lastName="ngModel" pInputText name="lastName" [(ngModel)]="model.lastName"
                            minlength="3" maxlength="40" required [autofocus]="true" class="w-full md:w-30rem mb-1" />

                        <div *ngIf="submitted && !lastName.valid && (lastName.dirty || lastName.touched)">
                            <small class="p-error" *ngIf="lastName.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="lastName.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: lastName.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="lastName.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: lastName.errors?.['maxlength'].requiredLength } }}
                            </small>
                        </div>
                        <div class="flex align-items-center justify-content-between">
                        </div>
                        <button pButton pRipple label="{{'messages.register' |
                                translate}}" class="w-full p-3 md:w-30rem mt-3"></button>

                        <div class="flex align-items-center justify-content-between mt-3 gap-5">
                            <a class="text-link" routerLink="../../login">{{'messages.backToLogin' |
                                translate}}</a>

                            <a class="font-medium no-underline ml-2 text-right cursor-pointer"
                                style="color: var(--primary-color)"
                                routerLink="../../email-confirm">{{'messages.resendMailConfirmation' |
                                translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-confirmDialog key="registerExternal"></p-confirmDialog>
</form>