import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../services/identity/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Pipe({
  name: 'secureImageDownload'
})
export class SecureImageDownloadPipe implements PipeTransform {
  constructor(private authenticationService: AuthenticationService, private httpClient: HttpClient) {

  }

  async transform(src: string): Promise<string> {
    const token = this.authenticationService.getUserAccessToken();
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    try {
      const imageBlob = await this.httpClient.get(src, { headers, responseType: 'blob' }).toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      return '';
    }
  }
}
