import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, PathLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { AddressingModule } from './modules/addressing/addressing.module';
import { SharedModule } from './modules/shared/shared.module';
import { GlobalErrorHandler } from './modules/shared/services/infra/global-error-handler';
import { ErrorDialogService } from './modules/shared/errors/error-dialog.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationInterceptor } from './modules/shared/interceptors/authentication.interceptor';
import { AppLayoutModule } from './modules/layout/app.layout.module';
import localePt from '@angular/common/locales/pt';
import { SecureImageDownloadPipe } from './modules/shared/pipes/secure-image-download.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { IdentityModule } from './modules/identity/identity.module';
import { AppInitializerService } from './modules/shared/services/medicius/AppInitializer';




registerLocaleData(localePt);
@NgModule({
    declarations: [
        AppComponent, NotfoundComponent,

    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        AppLayoutModule,
        SharedModule,
        AddressingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); },
                deps: [HttpClient]
            }
        }),
        QRCodeModule,
        IdentityModule

    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService,
        { provide: LOCALE_ID, useValue: 'pt' },
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        ErrorDialogService,
        MessageService,
        ConfirmationService,
        DialogService,
        SecureImageDownloadPipe,

        //        provideNgxMask(),
        AuthenticationInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        AppInitializerService,
        {
            provide: APP_INITIALIZER,
            useFactory: () => appInitializer,
            deps: [AppInitializerService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }


export function appInitializer(initializer: AppInitializerService) {
    // 
    // return (): Promise<void> => {
    //     return new Promise<void>((resolver) => {
    //         console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
    //         initializer.test().then(() => resolver());
    //     }
    //     );

    // };
}
