import { NamedBaseModelInt } from "../../infra/named-model"

export class ServiceOrderTypeModel extends NamedBaseModelInt {
    constructor(other?: ServiceOrderTypeModel | undefined) {
        super(other);

        if (other) {
            this.abbreviation = other.abbreviation;
            this.estimatedTimeMinutes = other.estimatedTimeMinutes;
            this.frontColor = other.frontColor;
            this.estimatedValue = other.estimatedValue;
        }
    }

    public abbreviation: string;
    public estimatedTimeMinutes: number = 30;
    public estimatedValue: number = 0;
    public frontColor: string = '#c7cfb8';
}
