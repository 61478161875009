import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpClientService } from "../infra/http-client.service";
import { BaseOperationService } from "../infra/base-operation.service";
import { StateModel } from "../../models/addressing/state-model";
import { Observable } from "rxjs";
import { OperationResult } from "../../models/response/operation-result";
import { OperationResults } from "../../models/response/operation-results";

@Injectable({
  providedIn: "root",
})
export class StateService extends BaseOperationService<StateModel> {
  protected override createInstance(instance: StateModel): StateModel {
    return new StateModel(instance);
  }
  protected override getUrlPath(): string {
    return "state";
  }

  public getAllByCountryId(
    countryId: number
  ): Observable<OperationResults<StateModel>> {
    return this.executeGet(`${this.getUrlPath()}/all/bycountry/${countryId}`);
  }
}
