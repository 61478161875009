import { NamedBaseModelInt } from "../../infra/named-model";
import { ContactPhoneTypeModel } from '../contact-phone-type-model';

export class PersonContactPhoneModel extends NamedBaseModelInt {

    constructor(other?: PersonContactPhoneModel | undefined) {
        super(other);
        if (other) {
            this.contactPhoneType = other.contactPhoneType ? new ContactPhoneTypeModel(other.contactPhoneType) : other.contactPhoneType;

            this.phoneNumber = other.phoneNumber;
            this.contactPhoneTypeId = other.contactPhoneTypeId;
            this.personId = other.personId;
        }
    }


    public personId: number;
    public phoneNumber: string;
    public contactPhoneTypeId: Number;
    public contactPhoneType: ContactPhoneTypeModel;

}
