import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../services/identity/authentication.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  if (!route.data['roles'] && !route.data['claims']) return true;;


  if (!!route.data['roles'] && authService.hasRole(route.data['roles'])) return true;
  return !!route.data['claims'] && authService.hasClaim(route.data['claims']);
};


