import { BaseModelInt } from "../../infra/base-model";
import { NamedBaseModelInt } from "../../infra/named-model";
import { CompanyModel } from "../comercial/company-model";
import { PatientPersonModel } from "../comercial/patient-person-model";
import { RiskCategoryModel } from "./risk-category-model";


export class RiskLevelTypeModel extends NamedBaseModelInt {

    constructor(other?: RiskLevelTypeModel | undefined) {
        super(other);
        if (other) {
            this.description = other.description;
            this.riskCategoryId = other.riskCategoryId;
            this.riskCategory = other.riskCategory ? new RiskCategoryModel(other.riskCategory) : other.riskCategory;
        }

    }

    public description: string;
    public riskCategoryId: number;
    public riskCategory: RiskCategoryModel;

    public get severity() {
        var n = this.name?.toUpperCase() || 'BAIXO';
        //
        switch (n) {
            case "ALTO":
                return 'danger';
            case "MÉDIO":
                return 'warning';
            case "BAIXO":
                return 'success';
            default:
                return 'info';
        }
    }
}


export class KnownRiskLevelEnum {
    constructor(other?: KnownRiskLevelEnum | undefined) {
        if (other) {
            this.id = other.id;
            this.name = other.name;
        }
    }

    id: number;
    name: string;
}