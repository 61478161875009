<form #form="ngForm" (ngSubmit)="save(form)" *ngIf="model">
    <p-card>
        <ng-template pTemplate="header">
            {{ (model.id ? "Edição" : "Cadastro")}}
        </ng-template>
        <div class="p-fluid">
            <div class="field hidden">
                <label>{{'messages.id' | translate}}</label>
                <input type="text" #id="ngModel" pInputText name="id" [(ngModel)]="model.id" autofocus
                    [disabled]="true" />
                <small class="p-error" *ngIf="id.touched && !model.id">{{'validators.required.id' |
                    translate}}</small>
            </div>
            <div class="field">
                <label>{{'messages.country' | translate}}</label>
                <p-autoComplete #country="ngModel" [(ngModel)]="selectedCountry" name="country"
                    [suggestions]="filteredCountries" (completeMethod)="filterCountry($event.query)"
                    appValidateAutoComplete [validValues]="countries" (onSelect)="onCountrySelect($event.value)"
                    field="name"
                    [ngClass]="(country.dirty || country.touched || !selectedCountry) ? 'ng-invalid ng-dirty' : ''  "
                    [disabled]="editMode" [autofocus]="model?.country?.id === 0" required></p-autoComplete>
                <div *ngIf="!country.valid && (country.dirty || country.touched)">
                    <small class="p-error" *ngIf="country.errors?.['required']">{{'validators.required.select' |
                        translate}}</small>
                    <small class="p-error" *ngIf="country.errors?.['invalidSelection']">{{'validators.required.select' |
                        translate}}</small>
                </div>
            </div>
            <div class="field">
                <label>{{'messages.name' | translate}}</label>
                <input type="text" #name="ngModel" pInputText name="name" [(ngModel)]="model.name" minlength="4"
                    maxlength="80" required [autofocus]="model?.country?.id > 0" />

                <div *ngIf="!name.valid && (name.dirty || name.touched)">
                    <small class="p-error" *ngIf="name.errors?.['required']">{{'validators.required.name' |
                        translate}}</small>
                    <small class="p-error" *ngIf="name.errors?.['minlength']">
                        {{'validators.minLength.name' |
                        translate: {min: name.errors?.['minlength'].requiredLength } }}
                    </small>
                    <small class="p-error" *ngIf="name.errors?.['maxlength']">
                        {{'validators.maxLength.name' |
                        translate: {max: name.errors?.['maxlength'].requiredLength } }}
                    </small>
                </div>
            </div>
            <div class="field">
                <label>{{'messages.fsName' | translate}}</label>
                <input type="text" #fsName="ngModel" pInputText name="fsName" [(ngModel)]="model.fsName" minlength="2"
                    maxlength="2" required />

                <div *ngIf="!fsName.valid && (fsName.dirty || fsName.touched)">
                    <small class="p-error" *ngIf="fsName.errors?.['required']">{{'validators.required.fsName' |
                        translate}}</small>
                    <small class="p-error" *ngIf="fsName.errors?.['minlength']">
                        {{'validators.minLength.fsName' |
                        translate: {min: fsName.errors?.['minlength'].requiredLength } }}
                    </small>
                    <small class="p-error" *ngIf="fsName.errors?.['maxlength']">
                        {{'validators.maxLength.fsName' |
                        translate: {max: fsName.errors?.['maxlength'].requiredLength } }}
                    </small>
                </div>
            </div>
            <div class="field hidden">
                <label> {{ model.cDate | dateTranslate: 'pipes.date.format' | async
                    }}</label>
                <p-calendar [(ngModel)]="model.cDate" [showTime]="true" [showSeconds]="true" name="cDate" required
                    appendTo="body" [iconDisplay]="'input'" [showIcon]="true" [disabled]="true"></p-calendar>
            </div>
            <div class="field hidden">
                <label> {{ model.mDate | dateTranslate: 'pipes.date.format' | async
                    }}</label>
                <p-calendar [(ngModel)]="model.mDate" [showTime]="true" [showSeconds]="true" name="mDate" required
                    appendTo="body" [iconDisplay]="'input'" [showIcon]="true" [disabled]="true"></p-calendar>
            </div>
            <div class="field hidden">
                <label>{{'messages.cUserName' | translate}}</label>
                <input type="text" #cUserName="ngModel" pInputText name="cUserName" [(ngModel)]="model.cUserName"
                    required autofocus [disabled]="true" />
                <div *ngIf="cUserName.invalid && (cUserName.dirty || cUserName.touched)">
                    <small class="p-error" *ngIf="name.errors?.['required']">{{'validators.required.cUserName' |
                        translate}}</small>

                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="d-flex flex-row-reverse">
                <button pButton pRipple label="{{ 'messages.cancel' | translate }}" icon="pi pi-times"
                    class="p-button-text" type="button" (click)="close()"></button>
                <button pButton pRipple label="{{ 'messages.save' | translate }}" icon="pi pi-check"
                    class="p-button-text"></button>
            </div>

        </ng-template>

    </p-card>
</form>