import { CommonModule, DatePipe } from '@angular/common';
import { ErrorDialogComponent } from './errors/error-dialog/error-dialog.component';
import { DefaultErrorComponent } from './errors/error-dialog/default-error/default-error.component';
import { NotFoundComponent } from './errors/error-dialog/not-found/not-found.component';
import { BadRequestComponent } from './errors/error-dialog/bad-request/bad-request.component';
import { DefaultModalComponent } from './notifications/default-modal/default-modal.component';
import { DefaultSnackbarComponent } from './notifications/default-snackbar/default-snackbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AngularPrimeNgModule } from './angular-primeng-imports.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateTranslateComponentFormatPipe, DateTranslatePipe } from './pipes/validator-translate.pipe';
import { ValidateAutoCompleteDirective } from './directives/validate-auto-complete.directive';
import { ValidateCpfDirective } from './directives/validate-cpf.directive';
import { ValidateRgDirective } from './directives/validate-rg.directive';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CalendarDateFormatDirective } from './directives/calendar-date-format.directive';
import { ValidateCNPJDirective } from './directives/validate-cnpj.directive';
import { FileDropDirective } from './directives/file-upload/file-drop.directive';
import { FileSelectDirective } from './directives/file-upload/file-select.directive';
import { RoleGuardDirective } from './directives/role-guard.directive';
import { ErrorDialogService } from './errors/error-dialog.service';
import { DomainTypeRegistryComponent } from './components/domain-type-registry/domain-type-registry.component';
import { DynamicSearchPagedComponent } from './components/dynamic-search-paged/dynamic-search-paged.component';
import { DomainTypeRegistryAddEditComponent } from './components/domain-type-registry/domain-type-registry-add-edit/domain-type-registry-add-edit.component';
import { DynamicSearchPagedBaseComponent } from './components/dynamic-search-paged-base/dynamic-search-paged-base.component';
import { CalendarCommonModule } from 'angular-calendar';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { ResizableModule } from 'angular-resizable-element';
import { SecureImageDownloadPipe } from './pipes/secure-image-download.pipe';
import { BackgroundSecureImageDownloadDirective } from './directives/background-secure-image-download.directive';
import { DomainMultiSelectComponent } from './components/domain-multi-select/domain-multi-select.component';
import { DomainDropdownComponent } from './components/domain-dropdown/domain-dropdown.component';


@NgModule({
  declarations: [
    ErrorDialogComponent,
    DefaultModalComponent,
    DefaultErrorComponent,
    BadRequestComponent,
    NotFoundComponent,
    BadRequestComponent,
    DefaultSnackbarComponent,
    DateTranslatePipe,
    DateTranslateComponentFormatPipe,
    ValidateAutoCompleteDirective,
    ValidateCpfDirective,
    ValidateRgDirective,
    ValidateCNPJDirective,
    FileDropDirective,
    FileSelectDirective,
    RoleGuardDirective,
    DomainTypeRegistryComponent,
    DynamicSearchPagedComponent,
    DomainTypeRegistryAddEditComponent,
    SecureImageDownloadPipe,
    BackgroundSecureImageDownloadDirective,
    DomainMultiSelectComponent,
    DomainDropdownComponent,



  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularPrimeNgModule,
    TranslateModule,
    ResizableModule,
    DragAndDropModule,

    NgxMaskDirective, NgxMaskPipe, CalendarDateFormatDirective,
    // ScheduleModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory,
    // }),
  ],
  exports: [
    CommonModule,
    ErrorDialogComponent,
    DefaultModalComponent,
    DefaultErrorComponent,
    BadRequestComponent,
    NotFoundComponent,
    BadRequestComponent,
    DefaultSnackbarComponent,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    DateTranslatePipe,
    DateTranslateComponentFormatPipe,
    ValidateAutoCompleteDirective,
    ValidateCpfDirective,
    ValidateRgDirective,
    ValidateCNPJDirective,
    FileDropDirective,
    FileSelectDirective,
    AngularPrimeNgModule,
    NgxMaskDirective, NgxMaskPipe, CalendarDateFormatDirective,
    RoleGuardDirective,
    DomainTypeRegistryComponent,
    DynamicSearchPagedComponent,
    ResizableModule,
    DragAndDropModule,

    SecureImageDownloadPipe,
    BackgroundSecureImageDownloadDirective,
    DomainMultiSelectComponent,
    DomainDropdownComponent,

  ],
  providers: [DatePipe, provideNgxMask(), NgxMaskPipe]
})
export class SharedModule { }
