import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(protected _httpClient: HttpClient) {
    // _userProfile.getUserEmail();
  }
  protected getBaseUrl(): string {
    return environment.API_URL;
  }

  protected getUrlPath(): string {
    return '';
  }

  protected normalizeUrl(
    baseUrl: string,
    path: string | null | undefined
  ): string {
    var url = '';
    path =
      path == null || path == undefined || path == ''
        ? this.getUrlPath()
        : path;
    if (baseUrl.endsWith('/') && path.startsWith('/')) {
      url = baseUrl + path.substring(1);
    } else if (!baseUrl.endsWith('/') && !path.startsWith('/')) {
      url = baseUrl + '/' + path;
    } else {
      url = baseUrl + path;
    }

    return url;
  }

  // HttpClient API get() method => Fetch details
  public executeGet<T>(
    path?: string | null | undefined,
    headers?: HttpHeaders | null | undefined
  ): Observable<T> {
    if (headers)
      return this._httpClient.get<T>(
        `${this.normalizeUrl(this.getBaseUrl(), path)}`,
        { headers }
      );
    return this._httpClient.get<T>(
      `${this.normalizeUrl(this.getBaseUrl(), path)}`
    );
  }

  // HttpClient API get() method => Fetch details
  public executeGetList<T>(
    path?: string | null | undefined
  ): Observable<Array<T>> {
    return this._httpClient.get<T[]>(
      `${this.normalizeUrl(this.getBaseUrl(), path)}`
    );
  }

  public executePostList<T>(
    payloads?: any | null | undefined,
    path?: string | null | undefined
  ): Observable<T[]> {
    const url = `${this.normalizeUrl(this.getBaseUrl(), path)}`;
    return <Observable<T[]>>(
      this._httpClient.post(
        `${this.normalizeUrl(this.getBaseUrl(), path)}`,
        payloads
      )
    );
  }
  // HttpClient API post() method => Create new record
  public executePost<T>(
    payloads?: any | null | undefined,
    path?: string | null | undefined
  ): Observable<T> {
    return <Observable<T>>(
      this._httpClient.post(
        `${this.normalizeUrl(this.getBaseUrl(), path)}`,
        payloads
      )
    );
  }

  public executePut<T>(
    payloads?: any | null | undefined,
    path?: string | null | undefined
  ): Observable<T> {
    return <Observable<T>>(
      this._httpClient.put(
        `${this.normalizeUrl(this.getBaseUrl(), path)}`,
        payloads
      )
    );
  }

  public executeDelete<T>(path?: string | null | undefined): Observable<T> {
    return <Observable<T>>(
      this._httpClient.delete(`${this.normalizeUrl(this.getBaseUrl(), path)}`)
    );
  }

  public executeDeleteWithBody<T>(
    payloads?: any | null | undefined,
    path?: string | null | undefined
  ): Observable<T> {
    return <Observable<T>>(
      this._httpClient.request(
        'delete',
        `${this.normalizeUrl(this.getBaseUrl(), path)}`,
        { body: payloads }
      )
    );
  }

  public executePatch<T>(
    payloads?: any | null | undefined,
    path?: string | null | undefined
  ): Observable<T> {
    return <Observable<T>>(
      this._httpClient.patch(
        `${this.normalizeUrl(this.getBaseUrl(), path)}`,
        payloads
      )
    );
  }

  public executeUpload<T>(
    payloads: any,
    files: Array<File>,
    path?: string | null | undefined
  ): Observable<HttpEvent<unknown>> {

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    //formData.encType = 'multipart/form-data'
    if (payloads)
      formData.append('payload', JSON.stringify(payloads));

    let params = new HttpParams();

    const url = `${this.normalizeUrl(this.getBaseUrl(), path)}`;

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this._httpClient.request(req);
  }

  protected createServiceQueryParams(paramObject: any): string {
    const params = this.createQueryParams(paramObject);
    return params && params.length > 0 ? `?${params}` : params;
  }

  private createQueryParams(paramObject: any, prefix?: string): string {
    const params = Object.keys(paramObject)
      .map((key) => {
        const value = paramObject[key];
        if (!this.isObject(value)) {
          // if(Array.isArray(value)) {
          //   if(value.some(x => this.isObject(x))) {
          //     return value.map((innerValue, index) => {
          //       return prefix ? `${prefix}.${this.createQuery(value, `${key}[${index}]`)}` : this.createQuery(value, `${key}[${index}]`);
          //     });
          //   } else {
          //     const arraParam = prefix ? `${prefix}.${key}=${encodeURIComponent(value.join(','))}` : `${key}=${encodeURIComponent(value.join(','))}`;
          //     return arraParam;
          //   }
          // } else {
          const param = prefix
            ? `${prefix}.${key}=${encodeURIComponent(value)}`
            : `${key}=${encodeURIComponent(value)}`;

          return param;
          //}
        }
        return prefix
          ? `${prefix}.${this.createQueryParams(value, key)}`
          : this.createQueryParams(value, key);
      })
      .join('&');
    return params;
  }

  private isObject(value: any) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }
}
