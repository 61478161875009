import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorDialogService } from "../../errors/error-dialog.service";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private zone: NgZone
  ) {


  }

  handleError(error: any) {
    console.log("Error from global error handler", error);
    console.log(error);

    const isHttpError = error instanceof HttpErrorResponse;
    if (!isHttpError && error.rejection) {
      error = error.rejection; // get the error object
    }

    const errorDialogService = this.injector.get(ErrorDialogService);
    // Check if it's an error from an HTTP response
    this.zone.run(() =>
      errorDialogService.openDialog(
        error
      )
    );
  }
}
