import { NamedBaseModelInt } from "../infra/named-model";
import { ProductModel } from "./product-model";

export class DbFileModel extends NamedBaseModelInt {
    constructor(other?: DbFileModel | undefined) {
        super(other);
        if (other) {
            this.extension = other.extension;
        }

    }


    public extension: string;
}
