import { LoginExternalModel } from './login-external-model';

export class RegisterExternalModel extends LoginExternalModel {
    firstName: string;
    lastName: string;


    constructor(other?: RegisterExternalModel) {
        super(other);
        if (other) {
            this.firstName = other.firstName;
            this.lastName = other.lastName;
        }
    }
}