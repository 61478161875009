import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { CountryComponent } from "./components/country/country.component";
import { StateComponent } from "./components/state/state.component";
import { CityComponent } from "./components/city/city.component";
import { NeighborhoodComponent } from "./components/neighborhood/neighborhood.component";
import { PhysicalLocationTypeComponent } from "./components/physical-location-type/physical-location-type.component";
import { PhysicalLocationComponent } from "./components/physical-location/physical-location.component";
import { roleGuard } from "../shared/guards/role.guard";


const routes: Routes = [
  {
    path: "countries",
    component: CountryComponent,
    canActivate: [roleGuard],
    data: {
      roles: ['ADMIN', "ADDRESS_OPERATOR"]
    }
  },
  {
    path: "states",
    component: StateComponent,
  },
  {
    path: "cities",
    component: CityComponent,
  },
  {
    path: "neighborhoods",
    component: NeighborhoodComponent,
  },
  {
    path: "placetypes",
    component: PhysicalLocationTypeComponent,
  },
  {
    path: "physicallocations",
    component: PhysicalLocationComponent,
  }
  // {
  //   path: "neighborhood",
  //   component: NeighborhoodComponent,
  // },
  // {
  //   path: "neighborhood/add",
  //   component: NeighborhoodItemComponent,
  // },
  // {
  //   path: "neighborhood/edit/:id",
  //   component: NeighborhoodItemComponent,
  // },

  // {
  //   path: "physicallocationtype",
  //   component: PhysicalLocationTypeComponent,
  // },
  // {
  //   path: "neighborhphysicallocationtype/add",
  //   component: PhysicalLocationTypeItemComponent,
  // },
  // {
  //   path: "physicallocationtype/edit/:id",
  //   component: PhysicalLocationTypeItemComponent,
  // },

  // {
  //   path: "physicallocation",
  //   component: PhysicalLocationComponent,
  // },
  // {
  //   path: "neighborhphysicallocation/add",
  //   component: PhysicalLocationItemComponent,
  // },
  // {
  //   path: "physicallocationtype/edit/:id",
  //   component: PhysicalLocationItemComponent,
  // },

  // {
  //   path: "search",
  //   component: AddressSearcherComponent,
  // },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AddressingRoutingModule { }
