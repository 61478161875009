import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/identity/authentication.service';

import { FormGroup, NgForm } from '@angular/forms';
import { ResetPasswordModel } from '../../../shared/models/request/identity/reset-password-model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  submitted: boolean = false;
  currentForm!: FormGroup;

  constructor(protected service: AuthenticationService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,) {

  }

  model = new ResetPasswordModel();

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.model.confirmationCode = params.get('confirmationCode');
      this.model.email = params.get('email');

      if (this.model.confirmationCode && this.model.email) {

      } else {
        this.translateService.get(['operations.passwordResetRequest.invalid.noParameters.header', 'operations.passwordResetRequest.invalid.noParameters.body']).subscribe((messages) => {
          const header = messages['operations.passwordResetRequest.invalid.noParameters.header'];
          const body = messages['operations.passwordResetRequest.invalid.noParameters.body'];

          this.confirmationService.confirm({
            message: body,
            header: header,
            key: "resetPassword",
            icon: 'pi pi-exclamation-triangle',
            rejectVisible: false,
            accept: () => {
              this.service.logout();
              this.router.navigateByUrl('/');
            }
          });
        });
      }
    });
  }


  resetPassword(form: NgForm): void {
    this.submitted = true;
    if (form.valid) {

      this.translateService.get(['operations.passwordResetRequest.success.confirmed.header', 'operations.passwordResetRequest.success.confirmed.body']).subscribe((messages) => {
        const header = messages['operations.passwordResetRequest.success.confirmed.header'];
        const body = messages['operations.passwordResetRequest.success.confirmed.body'];

        const subscription = this.service.resetPassword(this.model).subscribe({
          next: (result) => {
            this.confirmationService.confirm({
              message: body,
              header: header,
              key: "resetPassword",
              icon: 'pi pi-exclamation-triangle',
              rejectVisible: false,
              accept: () => {
                this.service.logout();
                this.router.navigate(['../login'], { relativeTo: this.activatedRoute });
              }
            });
          },
          complete: () => { subscription.unsubscribe(); this.submitted = false; }
        });

      });
    }
  }

}
