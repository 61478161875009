import { Injectable } from "@angular/core";
import { BaseOperationService } from "../infra/base-operation.service";
import { PhysicalLocationTypeModel } from "../../models/addressing/physical-location-type-model";

@Injectable({
  providedIn: "root",
})
export class PhysicalLocationTypeService extends BaseOperationService<PhysicalLocationTypeModel> {
  protected override getUrlPath(): string {
    return "physicallocationtype";
  }

  protected override createInstance(instance: PhysicalLocationTypeModel): PhysicalLocationTypeModel {
    return new PhysicalLocationTypeModel(instance);
  }
}
