import { NamedBaseModelInt } from "../../../infra/named-model";

export class AlergyTypeModel extends NamedBaseModelInt {
    constructor(other?: AlergyTypeModel | undefined) {
        super(other);
        if (other) {

        }
    }
}
