import { Injectable } from "@angular/core";
import { TokenInfoModel } from '../../models/response/user-auth-model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenService {
  // public access_token?: string;
  // public refreshToken?: string;

  public createTokenInfo(payload: string): TokenInfoModel {
    var decodedToken = this.decodeJwtPayload(payload);
    const tki: TokenInfoModel = JSON.parse(decodedToken);
    tki.iatD = new Date(tki.iat * 1000);
    tki.expD = new Date(tki.exp * 1000);
    if (typeof tki.role === "string") {
      tki.role = [tki.role];
    }
    return tki;
  }

  public isExpired(tki: TokenInfoModel): boolean {
    if (!tki || !tki.exp || !tki.iat) return true;
    const missingSecondsToExpire = this.missingSecondsToExpire(tki);
    return missingSecondsToExpire <= 0;
  }

  public missingSecondsToExpire(tki: TokenInfoModel): number {
    const now = new Date();
    const expDate = new Date(tki.exp * 1000);
    const missing = (expDate.getTime() - now.getTime()) / 1000;
    return Math.trunc(missing);
  }

  public willExpireOnNextInterval(tki: TokenInfoModel, interval: number): boolean {
    if (!tki || !tki.exp || !tki.iat) return true;
    const missingSecondsToExpire = this.missingSecondsToExpire(tki);
    return missingSecondsToExpire < (interval / 1000);
  }

  public decodeJwtPayload(payload: string): string {
    if (payload.length === 0) {
      throw new Error(`Token inválido, precisa conter 3 partes.`);
    }

    const parts = payload.split(".");

    if (parts.length !== 3) {
      throw new Error(
        `Token inválido, precisa conter 3 partes.`
      );
    }

    let decoded;
    try {
      decoded = atob(parts[1]);
    } catch (e) {
      throw new Error(
        `Token inválido, não foi possível convertê-lo.`
      );
    }

    if (!decoded) {
      throw new Error(
        `Token inválido, não foi possível convertê-lo.`
      );
    }
    return decoded;
  }

}
