import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryComponent } from './components/country/country.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AngularPrimeNgModule } from '../shared/angular-primeng-imports.module';
import { AddressingRoutingModule } from './addressing-routing.module';
import { CountryItemComponent } from './components/country/country-item/country-item.component';
import { StateItemComponent } from './components/state/state-item/state-item.component';
import { StateComponent } from './components/state/state.component';
import { CityItemComponent } from './components/city/city-item/city-item.component';
import { CityComponent } from './components/city/city.component';
import { NeighborhoodComponent } from './components/neighborhood/neighborhood.component';
import { NeighborhoodItemComponent } from './components/neighborhood/neighborhood-item/neighborhood-item.component';
import { PhysicalLocationTypeItemComponent } from './components/physical-location-type/physical-location-type-item/physical-location-type-item.component';
import { PhysicalLocationTypeComponent } from './components/physical-location-type/physical-location-type.component';
import { PhysicalLocationItemComponent } from './components/physical-location/physical-location-item/physical-location-item.component';
import { PhysicalLocationComponent } from './components/physical-location/physical-location.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CountryComponent,
    CountryItemComponent,
    StateComponent,
    StateItemComponent,
    CityComponent,
    CityItemComponent,
    NeighborhoodComponent,
    NeighborhoodItemComponent,
    PhysicalLocationTypeComponent,
    PhysicalLocationTypeItemComponent,
    PhysicalLocationComponent,
    PhysicalLocationItemComponent,

    // PhysicalAddressTypeComponent,
    // PhysicalAddressTypeTableComponent,
    // PhysicalAddressTypeItemComponent,
    // PhysicalAddressTypeSelectComponent,
    // PhysicalAddressComponent,
    // PhysicalAddressTableComponent,
    // PhysicalAddressItemComponent,
    // PhysicalAddressSelectComponent,


    // AddressSearcherComponent,
    // AddressSearcherSelectComponent,
  ],
  imports: [
    AddressingRoutingModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,


  ], providers: [

  ]
})
export class AddressingModule { }
