import { NamedBaseModelInt } from "../../infra/named-model";
import { ServiceOrderWorkflowStepModel } from "../service-records/service-order-workflow-step-model";
import { CompanyModel } from "./company-model";
import { CompanySectorProductModel } from "./company-sector-product-model";

export class CompanySectorModel extends NamedBaseModelInt {
    constructor(other?: CompanySectorModel | undefined) {
        super(other);
        if (other) {
            this.companyId = other.companyId;
            this.enabled = other.enabled;

            this.company = other.company ? new CompanyModel(other.company) : other.company;
            this.companySectorProduct = other.companySectorProduct ? other.companySectorProduct.map(x => new CompanySectorProductModel(x)) : other.companySectorProduct;
            this.serviceOrderWorkflowSteps = other.serviceOrderWorkflowSteps ? other.serviceOrderWorkflowSteps.map(x => new ServiceOrderWorkflowStepModel(x)) : other.serviceOrderWorkflowSteps;
        }

    }

    public companyId: number;
    public company: CompanyModel;
    public enabled: boolean = false;

    public companySectorProduct: CompanySectorProductModel[] = [];

    public serviceOrderWorkflowSteps: ServiceOrderWorkflowStepModel[] = [];
}
