<div class="card">
    <p-toast></p-toast>
    <p-confirmDialog key="delete"></p-confirmDialog>
    <p-toolbar styleClass="mb-1 gap-2">
        <ng-template pTemplate="left">
            <div class="field">

            </div>
        </ng-template>
        <ng-template pTemplate="right">
            <button pButton pRipple label="{{ 'messages.new' | translate }}" icon="pi pi-plus"
                class="p-button-success mr-2" (click)="openRegistry(null)"></button>
            <button pButton pRipple label="{{ 'messages.del' | translate }}" icon="pi pi-trash" class="p-button-danger"
                (click)="deleteSelectedItems()" [disabled]="!selectedItems || !selectedItems.length"></button>
        </ng-template>
    </p-toolbar>

    <p-table #mTable [value]="models" [rows]="10" [paginator]="true" [globalFilterFields]="['id', 'name', 'fsName']"
        [tableStyle]="{ 'min-width': '75rem' }" [(selection)]="selectedItems" [rowHover]="true" dataKey="id"
        styleClass="p-datatable-gridlines" [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'primeng.currentPageReport' | translate:{ 'firstRecord': 'first', 'lastRecord': 'last', 'totalRecords': 'totalRecords' } }}">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <span>
                    <p-autoComplete [dropdown]="true" [(ngModel)]="selectedCountry" [suggestions]="filteredCountries"
                        pAutoFocus [autofocus]="true" (onSelect)="onCountrySelect($event.value)"
                        (completeMethod)="filterCountry($event.query)" field="name"
                        placeholder="{{ 'messages.selectCountry' | translate }}" class="mr-4"></p-autoComplete>

                    <p-autoComplete [dropdown]="true" [(ngModel)]="selectedState" [suggestions]="filteredStates"
                        pAutoFocus (onSelect)="onStateSelected($event.value)"
                        (completeMethod)="filterState($event.query)" field="name"
                        placeholder="{{ 'messages.selectState' | translate }}" class="mr-4"></p-autoComplete>

                    <p-autoComplete [dropdown]="true" [(ngModel)]="selectedCity" [suggestions]="filteredCities"
                        pAutoFocus (onSelect)="onCitySelected($event.value)" (completeMethod)="filterCity($event.query)"
                        field="name" placeholder="{{ 'messages.selectCity' | translate }}"
                        class="mr-4"></p-autoComplete>
                </span>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" #gFilter (input)="applyTableFilterGlobal(mTable, $event, 'contains')"
                        placeholder="{{ 'messages.search' | translate }}" />
                    <button pButton label="{{ 'messages.clear' | translate  }}" class="p-button-outlined"
                        icon="pi pi-filter-slash" (click)="clearFilters(mTable, gFilter)"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="id"> {{'messages.id' | translate}}
                    <p-sortIcon field="id"></p-sortIcon>
                    <p-columnFilter type="numeric" field="id" display="menu"></p-columnFilter>
                </th>
                <th pSortableColumn="name">{{'messages.name' | translate}}
                    <p-sortIcon field="name"></p-sortIcon>
                    <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                </th>
                <th pSortableColumn="fsName">{{'messages.fsName' | translate}}
                    <p-sortIcon field="fsName"></p-sortIcon>
                    <p-columnFilter type="text" field="fsName" display="menu"></p-columnFilter>
                </th>
                <!-- <th pSortableColumn="cDate">{{'messages.cDate' | translate}}
                    <p-sortIcon field="cDate"></p-sortIcon>
                    <p-columnFilter type="date" field="cDate" display="menu"></p-columnFilter>
                </th>
                <th pSortableColumn="mDate">{{'messages.mDate' | translate}}
                    <p-sortIcon field="mDate"></p-sortIcon>
                    <p-columnFilter type="date" field="mDate" display="menu"></p-columnFilter>
                </th>
                <th pSortableColumn="cUserName">{{'messages.cUserName' | translate}}
                    <p-sortIcon field="cUserName"></p-sortIcon>
                    <p-columnFilter type="text" field="cUserName" display="menu"></p-columnFilter>
                </th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-model>
            <tr>
                <td>
                    <p-tableCheckbox [value]="model"></p-tableCheckbox>
                </td>
                <td>{{ model.id }}</td>
                <td>{{ model.name }}</td>
                <td>{{ model.fsName }}</td>
                <!-- <td>{{ model.cDate | date: "dd/MM/yyy HH:mm:ss" }}</td>
                <td>{{ model.mDate | date: "dd/MM/yyy HH:mm:ss" }}</td>
                <td>{{ model.cUserName }}</td> -->
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                        (click)="editModel(model)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        (click)="deleteModel(model)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>