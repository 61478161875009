import { NamedBaseModelInt } from "../infra/named-model";
import { NeighborhoodModel } from "./neighborhood-model";
import { PhysicalLocationTypeModel } from "./physical-location-type-model";

export class PhysicalLocationModel extends NamedBaseModelInt {
  constructor(other?: PhysicalLocationModel) {
    super(other);
    if (other) {
      this.neighborhood = other.neighborhood ? new NeighborhoodModel(other.neighborhood) : new NeighborhoodModel();
      this.physicalLocationType = other.physicalLocationType ? new PhysicalLocationTypeModel(other.physicalLocationType) : new PhysicalLocationTypeModel;


      this.neighborhoodId = other.neighborhoodId;
      this.physicalLocationTypeId = other.physicalLocationTypeId;
    }
  }


  public neighborhoodId: number = 0;

  private _neighborhood: NeighborhoodModel;
  public get neighborhood(): NeighborhoodModel {
    return this._neighborhood;
  }
  public set neighborhood(v: NeighborhoodModel) {
    this._neighborhood = v;
    if (v) {
      this.neighborhoodId = v.id;
    }
  }

  public physicalLocationTypeId: number = 0;

  private _physicalLocationType: PhysicalLocationTypeModel;
  public get physicalLocationType(): PhysicalLocationTypeModel {
    return this._physicalLocationType;
  }
  public set physicalLocationType(v: PhysicalLocationTypeModel) {
    this._physicalLocationType = v;
    if (v) {
      this.physicalLocationTypeId = v.id;
    }
  }
}
