import { NamedBaseModelInt } from "../../infra/named-model";
import { CNESModel } from "./health/cnes-model";
import { CompanyContactAddressModel } from "./company-contact-address-model";
import { CompanyContactPhoneModel } from "./company-contact-phone-model";
import { CNESTypeModel } from "./health/cnes-type-model";
import { CompanySectorModel } from "./company-sector-model";
import { CompanyLocationModel } from "./company-location-model";

export class CompanyModel extends NamedBaseModelInt {
    constructor(other?: CompanyModel | undefined) {
        super(other);
        if (other) {
            this.identifier = other.identifier;
            this.fantasyName = other.fantasyName;
            this.email = other.email;
            this.cnesId = other.cnesId;
            this.cnesTypeId = other.cnesTypeId;

            this.companySectors = other.companySectors ? other.companySectors.map(x => new CompanySectorModel(x)) : other.companySectors;
            this.companyLocations = other.companyLocations ? other.companyLocations.map(x => new CompanyLocationModel(x)) : other.companyLocations;
            this.contactAddresses = other.contactAddresses ? other.contactAddresses.map(x => new CompanyContactAddressModel(x)) : other.contactAddresses;
            this.contactPhones = other.contactPhones ? other.contactPhones.map(x => new CompanyContactPhoneModel(x)) : other.contactPhones;
            this.cnesType = other.cnesType ? new CNESTypeModel(other.cnesType) : other.cnesType;



        }
    }

    public identifier: string = '';
    public contactAddresses: CompanyContactAddressModel[] | null = [];
    public contactPhones: CompanyContactPhoneModel[] | null = [];


    public fantasyName: string;
    public email: string;

    public cnesId: string;

    public cnesTypeId: number;
    public cnesType: CNESTypeModel;


    public companySectors: CompanySectorModel[];
    public companyLocations: CompanyLocationModel[];

}
