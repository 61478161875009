import { NamedBaseModelInt } from "../../infra/named-model";
import { ContactPhoneTypeModel } from '../contact-phone-type-model';
import { CompanyModel } from "./company-model";

export class CompanyContactPhoneModel extends NamedBaseModelInt {

    constructor(other?: CompanyContactPhoneModel | undefined) {
        super(other);
        if (other) {
            this.contactPhoneType = other.contactPhoneType ? new ContactPhoneTypeModel(other.contactPhoneType) : other.contactPhoneType;

            this.phoneNumber = other.phoneNumber;
            this.contactPhoneTypeId = other.contactPhoneTypeId;
            this.companyId = other.companyId;
        }
    }


    public companyId: number;
    public company: CompanyModel = null;
    public phoneNumber: string;
    public contactPhoneTypeId: Number;
    public contactPhoneType: ContactPhoneTypeModel;

}
