import { BaseModelInt } from "../../infra/base-model";
import { PersonModel } from "../personal/person-model";
import { AlergyModel } from "./health/alergy-model";
import { PatientBastionPersonModel } from "./patient-bastion-person-model";
import { PatientPersonModel } from "./patient-person-model";

export class PatientPersonAlergyModel extends BaseModelInt {
    constructor(other?: PatientPersonAlergyModel | undefined) {
        super(other);
        if (other) {
            this.patientId = other.patientId;
            this.patientPerson = other.patientPerson ? new PatientPersonModel(other.patientPerson) : other.patientPerson;

            this.alergyId = other.alergyId;
            this.alergy = other.alergy ? new AlergyModel(other.alergy) : other.alergy;

        }
    }

    public patientId: number;
    public patientPerson: PatientPersonModel;

    public alergyId: number;
    public alergy: AlergyModel;
}