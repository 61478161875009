import { Component } from '@angular/core';
import { CompanyModel } from '../../shared/models/medicius/comercial/company-model';
import { UserCompanySectorsService } from '../../shared/services/business/user-company-sectors.service';
import { AuthenticationService } from '../../shared/services/identity/authentication.service';
import { UserAuthModel } from '../../shared/models/response/user-auth-model';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss'
})
export class AppHeaderComponent {
  myCompanies: CompanyModel[];
  selectedSector: number;
  selectedLocation: number;
  user: UserAuthModel;

  constructor(
    public authenticationService: AuthenticationService,
    public userCompanySectorsService: UserCompanySectorsService,
  ) {
    authenticationService.user$.subscribe({
      next: (user) => {
        this.user = user;
      }
    });

    userCompanySectorsService.myCompanies$.subscribe({
      next: (myCompanies: CompanyModel[]) => {
        this.myCompanies = myCompanies;
        this.prepareCompanySectorDefault();
        this.prepareCompanyLocationDefault();
      }
    });
  }

  prepareCompanySectorDefault() {
    this.selectedSector = this.userCompanySectorsService.getMyCurrentCompanySector()?.id;
    if (!this.selectedSector && this.myCompanies?.length && this.myCompanies[0].companySectors?.length) {
      var sector = this.myCompanies[0].companySectors[0];
      this.userCompanySectorsService.setMyCurrentCompanySector(sector);
      this.selectedSector = sector.id;
    }
  }

  prepareCompanyLocationDefault() {
    this.selectedLocation = this.userCompanySectorsService.getMyCurrentCompanyLocation()?.id;
    if (!this.selectedLocation && this.myCompanies?.length && this.myCompanies[0].companyLocations?.length) {
      var location = this.myCompanies[0].companyLocations[0];
      this.userCompanySectorsService.setMyCurrentCompanyLocation(location);
      this.selectedLocation = location.id;
    }
  }
}
