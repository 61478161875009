<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="/">
        <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg"
            alt="logo">
        <span>EgoMedicius</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()"
        pTooltip="Esconda ou mostre o menu esquerdo clicando aqui!">
        <i class="pi pi-bars"></i>
    </button>
    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>


    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

        <a class="p-link layout-topbar-button" routerLink="/identity/login"
            pTooltip="Entre com seu usuário clicando aqui!" *ngIf="(authenticationService.user$ | async) === null">
            <i class="pi pi-sign-in"></i>
            <span>Entrar</span>
        </a>

        <a class="p-link layout-topbar-button" routerLink="/identity/register"
            pTooltip="Cadastre seu usuário clicando aqui!" *ngIf="(authenticationService.user$ | async) === null">
            <i class="pi pi-user-plus"></i>
            <span>Registrar</span>
        </a>

        <button class="p-link layout-topbar-button"
            (click)="!layoutService.isMobile() ? userMenu.toggle($event) : authenticationService.logout()"
            *ngIf="(authenticationService.user$ | async) !== null">
            <i class="pi pi-user"></i>
            <span>Sair</span>
        </button>
        <p-menu #userMenu [model]="items" [popup]="true">
            <ng-template pTemplate="item" let-item>
                <a pRipple class="flex align-items-center p-menuitem-link">
                    <span class="ml-2">{{ item.label }}</span>
                    <p-badge *ngIf="item.badge" class="ml-auto" [value]="item.badge" />
                    <span *ngIf="item.shortcut"
                        class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
                        {{ item.shortcut }}
                    </span>
                </a>
            </ng-template>
        </p-menu>

        <!-- <button class="p-link layout-topbar-button" [routerLink]="'/documentation'"
            *ngIf="(authenticationService.user$ | async) === null">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </button> -->
    </div>
</div>