import { BaseModelInt } from "../../infra/base-model";
import { CompanySectorModel } from "../comercial/company-sector-model";
import { PatientPersonModel } from "../comercial/patient-person-model";
import { DbFileModel } from "../db-file-model";
import { RiskLevelTypeModel } from "./risk-level-type-model";
import { ServiceOrderModel } from "./service-order-model";
import { ServiceOrderWorkflowStepDbFile } from "./service-order-workflow-step-db-file-model";

export class ServiceOrderWorkflowStepModel extends BaseModelInt {
    constructor(other?: ServiceOrderWorkflowStepModel | undefined) {
        super(other);
        if (other) {
            this.patient = other.patient ? new PatientPersonModel(other.patient) : other.patient;
            this.serviceOrder = other.serviceOrder ? new ServiceOrderModel(other.serviceOrder) : other.serviceOrder;
            this.companySector = other.companySector ? new CompanySectorModel(other.companySector) : other.companySector;
            this.riskLevelType = other.riskLevelType ? new RiskLevelTypeModel(other.riskLevelType) : other.riskLevelType;
            this.workflowStepDbFiles = other.workflowStepDbFiles ? other.workflowStepDbFiles.map(x => new ServiceOrderWorkflowStepDbFile(x)) : other.workflowStepDbFiles;

            this.serviceOrderId = other.serviceOrderId;
            this.patientId = other.patientId;
            this.riskLevelTypeId = other.riskLevelTypeId;
            this.description = other.description;
            this.companySectorId = other.companySectorId;
            this.openDate = other.openDate && typeof other.openDate === "string" ? new Date(other.openDate) : other.openDate;
            this.closeDate = other.closeDate && typeof other.closeDate === "string" ? new Date(other.closeDate) : other.closeDate;

        }
    }

    serviceOrderId: number;
    serviceOrder: ServiceOrderModel;
    patientId: number;
    patient: PatientPersonModel;
    companySectorId: number;
    companySector: CompanySectorModel;
    description: string;
    workflowStepDbFiles: ServiceOrderWorkflowStepDbFile[] = [];
    openDate: Date;
    closeDate: Date;

    riskLevelTypeId: number;
    riskLevelType: RiskLevelTypeModel;
}
