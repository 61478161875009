import { Component, Input, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-default-error",
  templateUrl: "./default-error.component.html",
  styleUrls: ["./default-error.component.scss"],
})
export class DefaultErrorComponent implements OnInit, OnDestroy {


  private _error: any;
  public get error(): any {
    return this._error;
  }
  @Input() public set error(v: any) {

    this._error = v;
  }


  ngOnInit(): void {
    console.log("init DefaultErrorComponent: error data", this.error);
  }

  ngOnDestroy(): void {
    console.log("destroy DefaultErrorComponent");
  }
}
