import { Injectable } from "@angular/core";
import { HttpClientService } from "../infra/http-client.service";
import { HttpClient } from "@angular/common/http";
import { BaseOperationService } from "../infra/base-operation.service";
import { CityModel } from "../../models/addressing/city-model";
import { Observable } from "rxjs";
import { OperationResults } from "../../models/response/operation-results";

@Injectable({
  providedIn: "root",
})
export class CityService extends BaseOperationService<CityModel> {
  protected override getUrlPath(): string {
    return "city";
  }

  protected override createInstance(instance: CityModel): CityModel {
    return new CityModel(instance);
  }

  public getAllByStateId(
    stateId: number
  ): Observable<OperationResults<CityModel>> {
    return this.executeGet(`${this.getUrlPath()}/all/bystate/${stateId}`);
  }
}
