import { Injectable } from "@angular/core";
import { AppFrontConfigsModel } from "../../../models/medicius/appstuffs/app-front-configs-model";
import { BaseOperationService } from "../../infra/base-operation.service";
import { Observable, of, switchMap } from "rxjs";
import { OperationResults } from "../../../models/response/operation-results";


@Injectable({
    providedIn: 'root'
})
export class AppFrontConfigsService extends BaseOperationService<AppFrontConfigsModel> {
    protected override getUrlPath(): string {
        return "AppFrontConfigs";
    }

    protected override createInstance(instance: AppFrontConfigsModel): AppFrontConfigsModel {
        return new AppFrontConfigsModel(instance);
    }



    private _currentSettings: AppFrontConfigsModel = new AppFrontConfigsModel();
    public get currentSettings(): AppFrontConfigsModel {
        return this._currentSettings;
    }



    override getAll(): Observable<OperationResults<AppFrontConfigsModel>> {
        return super.getAll().pipe(switchMap((operationResult) => {

            if (operationResult?.items?.length) {
                this._currentSettings = operationResult.items[0];
            }
            return of(operationResult);
        }));
    }

}

