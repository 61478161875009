import { NamedBaseModelInt } from "../infra/named-model";

export class ContactPhoneTypeModel extends NamedBaseModelInt {

    constructor(other?: ContactPhoneTypeModel | undefined) {
        super(other);
        if (other) {
            this.frontMask = other.frontMask;
        }

    }

    public frontMask: string;
}
