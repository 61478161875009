import { AfterViewInit, Directive, ElementRef, Injector, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../services/identity/authentication.service';


@Directive({
  selector: '[appRoleGuard]'
})
export class RoleGuardDirective implements OnInit, AfterViewInit {
  @Input() roles: string[];
  @Input() claims: string[];
  // private readonly _elementRef: ElementRef;
  // private readonly _activatedRoute: ActivatedRoute;
  // private readonly _router: Router;
  private readonly _injector: Injector;
  // constructor() {
  //   
  // }
  constructor(
    private _elementRef: ElementRef,
    private authService: AuthenticationService
  ) {

  }

  ngAfterViewInit(): void {


  }

  async ngOnInit(): Promise<void> {
    this.authService.user$.subscribe((user) => {
      // //no roles and no claims defined
      // if (!this.roles?.length && !this.claims?.length) {

      // } else
      if (this.roles && this.roles.includes('PUBLIC') && !user) {
        //this._elementRef.nativeElement.style.display = 'block';
      } else if (!user) {
        this._elementRef.nativeElement.style.display = 'none';
      } else if (this.roles && this.roles.length > 0) {
        const hasAccess = this.authService.hasRole(this.roles);
        this._elementRef.nativeElement.style.display = hasAccess ? 'block' : 'none';
      } else if (this.claims && this.claims.length > 0) {
        const hasAccess = this.authService.hasClaim(this.claims);
        this._elementRef.nativeElement.style.display = hasAccess ? 'block' : 'none';
      }
    });
  }
}