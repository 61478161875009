<form (ngSubmit)="requestNewEmailConfirmationCode(form)" #form="ngForm" autocomplete="off">
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div>
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                    <div class="text-center mb-5">
                        <!-- <img src="assets/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3"> -->
                        <div class="text-900 text-3xl font-medium mb-3">{{ 'messages.signInWelcome' | translate}}</div>

                    </div>
                    <div>
                        <label [htmlFor]="email" class="block text-900 font-medium mb-2">{{'messages.email' |
                            translate}}</label>
                        <input type="text" #email="ngModel" pInputText name="email" [(ngModel)]="model.email"
                            minlength="8" maxlength="80" required [autofocus]="true" class="w-full md:w-30rem mb-1" />

                        <div *ngIf="submitted && !email.valid && (email.dirty || email.touched)">
                            <small class="p-error" *ngIf="email.errors?.['required']">{{'validators.required.name' |
                                translate}}</small>
                            <small class="p-error" *ngIf="email.errors?.['minlength']">
                                {{'validators.minLength.name' |
                                translate: {min: email.errors?.['minlength'].requiredLength } }}
                            </small>
                            <small class="p-error" *ngIf="email.errors?.['maxlength']">
                                {{'validators.maxLength.name' |
                                translate: {max: email.errors?.['maxlength'].requiredLength } }}
                            </small>
                        </div>
                        <div></div>
                        <button pButton pRipple label="{{'messages.resend' |
                                translate}}" class="w-full p-3 md:w-30rem mt-3"></button>

                        <div class="flex align-items-center justify-content-between mt-3 gap-5">
                            <a class="text-link" routerLink="../login">{{'messages.backToLogin' |
                                translate}}</a>
                            <a class="text-link" routerLink="../register">{{'messages.registerYourself' |
                                translate}}</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-confirmDialog key="mailConfirm"></p-confirmDialog>
</form>