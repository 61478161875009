import { NamedBaseModelInt } from "../infra/named-model";
import { NeighborhoodModel } from "./neighborhood-model";
import { StateModel } from "./state-model";

export class CityModel extends NamedBaseModelInt {
  constructor(other?: CityModel) {
    super(other);

    if (other) {
      this._state = other.state ? new StateModel(other.state) : new StateModel();
      this.neighborhoods = other.neighborhoods ? other.neighborhoods.map(x => new NeighborhoodModel(x)) : [];
      this.stateId = other.stateId;
    }
  }



  public stateId: number = null;
  //public state!: StateModel;

  private _state: StateModel = new StateModel();
  public get state(): StateModel {
    return this._state;
  }
  public set state(v: StateModel) {
    this._state = v;
    if (v) this.stateId = v.id;
  }

  public neighborhoods: Array<NeighborhoodModel> =
    new Array<NeighborhoodModel>();
}
