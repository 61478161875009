import { BaseModelInt } from "../../infra/base-model";
import { PersonModel } from "../personal/person-model";
import { PersonRelationshipDegreeTypeModel } from "../personal/person-relationship-degree-type-model";
import { PatientPersonModel } from "./patient-person-model";

export class PatientBastionPersonModel extends BaseModelInt {
    constructor(other?: PatientBastionPersonModel | undefined) {
        super(other);
        if (other) {
            this.patientId = other.patientId;
            this.bastionPersonId = other.bastionPersonId;
            this.personRelationshipDegreeTypeId = other.personRelationshipDegreeTypeId;
            this.patient = other.patient ? new PatientPersonModel(other.patient) : other.patient;
            this.bastionPerson = other.bastionPerson ? new PersonModel(other.bastionPerson) : other.bastionPerson;
            this.personRelationshipDegreeType = other.personRelationshipDegreeType ? new PersonRelationshipDegreeTypeModel(other.personRelationshipDegreeType) : other.personRelationshipDegreeType;
        }
    }



    public patientId: number;

    public patient: PatientPersonModel;

    public bastionPersonId: number;

    public bastionPerson: PersonModel;

    public personRelationshipDegreeTypeId: number;

    public personRelationshipDegreeType: PersonRelationshipDegreeTypeModel;
}