export class BaseModel<keyType> {
  constructor(other?: BaseModel<keyType>) {
    if (other) {
      this.id = other.id;
      if (typeof other.cDate === "string" && other.cDate) {
        this.cDate = new Date(other.cDate);
      }

      if (typeof other.mDate === "string" && other.mDate) {
        this.mDate = new Date(other.mDate);
      }

      this.cUserName = other.cUserName;
    }
  }

  public id: keyType;
  public cDate!: Date;
  public mDate!: Date;
  public cUserName: string = '';
}

export class BaseModelInt extends BaseModel<number> {
  constructor(other?: BaseModelInt | undefined) {
    super(other);
    this.id = other && other.id ? other.id : 0;
  }
}

export class BaseModelString extends BaseModel<String> {
  constructor(other?: BaseModelString) {
    super(other);
    this.id = other && other.id ? other.id : "";
  }
}
