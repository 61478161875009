import { DateComparisonType, NumberComparisonType, StringComparisonType } from "./filter-types";


export enum NumberIntervalComparisonType {
    Between
}

export enum DateIntervalComparisonType {
    Between
}

export class BaseFilter {
    propName!: string;
}

export class DynamicOrderProp extends BaseFilter {
    constructor(propName: string, direction: DynamicOrderDirection) {
        super();
        this.propName = propName;
        this.direction = direction;
    }

    public direction: DynamicOrderDirection = DynamicOrderDirection.ASC;
}

export enum DynamicOrderDirection {
    ASC = 1,
    DESC = -1
}

export class StringFilter extends BaseFilter {
    constructor(matchMode?: string | StringComparisonType | undefined) {
        super();
        if (matchMode) {
            if (typeof matchMode === 'string') {
                switch (matchMode.toLowerCase()) {
                    case "startswith":
                    case "starts with":
                        this.comparisonType = StringComparisonType.StartsWith;
                        break;

                    case "contains":
                        this.comparisonType = StringComparisonType.Contains;
                        break;
                    case "notcontains":
                    case "not contains":
                        this.comparisonType = StringComparisonType.NotContains;
                        break;
                    case "equals":
                        this.comparisonType = StringComparisonType.Equals;
                        break;
                    case "notequals":
                    case "not equals":
                        this.comparisonType = StringComparisonType.NotEquals;
                        break;
                    case "endswith":
                    case "ends with":
                        this.comparisonType = StringComparisonType.EndsWith;
                        break;
                    default:
                        throw new Error("Tipo de filtro desconhecido!");
                }
            }

            if (typeof matchMode !== 'string') {
                this.comparisonType = matchMode;
            }
        }

    }
    comparisonType!: StringComparisonType;
    val!: string;
}

export class NumberFilter extends BaseFilter {
    constructor(matchMode?: string | NumberComparisonType | undefined) {
        super();
        if (matchMode) {

            if (typeof matchMode === 'string') {
                switch (matchMode.toLowerCase()) {

                    case "equals":
                    case "eq":
                        this.comparisonType = NumberComparisonType.Equals;
                        break;
                    case "notequals":
                    case "eq":
                        this.comparisonType = NumberComparisonType.NotEquals;
                        break;

                    case "greater than":
                    case "gt":
                        this.comparisonType = NumberComparisonType.GT;
                        break;

                    case "greater than or equal to":
                    case "gte":
                        this.comparisonType = NumberComparisonType.GTE;
                        break;

                    case "less than":
                    case "lt":
                        this.comparisonType = NumberComparisonType.LT;
                        break;
                    case "less than or equal to":
                    case "lte":
                        this.comparisonType = NumberComparisonType.LTE;
                        break;
                    default:
                        throw new Error("Tipo de filtro desconhecido!");
                }
            }

            if (typeof matchMode !== 'string') {
                this.comparisonType = matchMode;
            }
        }

    }
    comparisonType!: NumberComparisonType;
    val!: number;
}

export class DateFilter extends BaseFilter {
    constructor(matchMode?: string | DateComparisonType | undefined) {
        super();
        if (matchMode) {

            if (typeof matchMode === 'string') {
                switch (matchMode.toLowerCase()) {

                    case "dateis":
                        this.comparisonType = DateComparisonType.Equals;
                        break;
                    case "dateisnot":
                        this.comparisonType = DateComparisonType.Equals;
                        break;

                    case "dateafter":
                        this.comparisonType = DateComparisonType.GT;
                        break;
                    case "datebefore":
                        this.comparisonType = DateComparisonType.LT;
                        break;

                    default:
                        throw new Error("Tipo de filtro desconhecido!");
                }
            }

            if (typeof matchMode !== 'string') {
                this.comparisonType = matchMode;
            }
        }

    }


    comparisonType!: DateComparisonType;
    val!: string;
}

export class NumberIntervalFilter extends BaseFilter {
    comparisonType!: NumberIntervalComparisonType;
    val!: number;
    eVal!: number;
}

export class DateIntervalFilter extends BaseFilter {
    comparisonType!: DateIntervalComparisonType;
    val!: string;
    eVal!: string;
}

export class DynamicFilters {
    public sFilters = new Array<StringFilter>();
    public nFilters = new Array<NumberFilter>();
    public dFilters = new Array<DateFilter>();
    public nIntervalFilters = new Array<NumberIntervalFilter>();
    public dIntervalFilters = new Array<DateIntervalFilter>();

    public orderProps = new Array<DynamicOrderProp>();

    public searchInfo: string;
}